<script setup lang="ts">
import {
  PaginatedQuery,
  ProductStatus,
  showProducts,
  Products,
  Product,
  formatStatus,
  ProductBulkRequest,
  ProductBulkResponse,
  ProductEditModel,
} from 'ah-api-gateways';
import { EditTableMode } from 'ah-common-lib/src/models';
import { useServices } from 'bd-common/src/services';
import { computed, PropType, ref, watch } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import { checkboxField, selectField } from 'ah-common-lib/src/form/models';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: [] }),
  },
  ...defineUseManagedListingProps<Product>(),
});

interface ProductsListingEmits extends UseManagedListingEmits<Product> {}

const emit = defineEmits<ProductsListingEmits>();

const services = useServices();

const managedListing = useManagedListing<Product>({
  loadDataRequest(query: PaginatedQuery) {
    return services.products.listProduct(query).pipe(
      mergeMap((response) => {
        response.list.map((item) => {
          let products: Products[] = [];
          if (item.fxSpotEnabled) {
            products.push(Products.SPOT);
          }

          if (item.fxForwardEnabled) {
            products.push(Products.FORWARD);
          }
          item.products = products;
          return { ...item };
        });
        return of(response);
      })
    );
  },
  saveEditedRowRequest(updatedEntry: Product): Observable<Product> {
    const fxSpotEnabled = updatedEntry.products?.includes(Products.SPOT) ?? false;
    const fxForwardEnabled = updatedEntry.products?.includes(Products.FORWARD) ?? false;

    const productEditModel: ProductEditModel = {
      id: updatedEntry.id,
      currencyPairEnabled: updatedEntry.currencyPairEnabled,
      fxSpotEnabled: fxSpotEnabled,
      fxForwardEnabled: fxForwardEnabled,
    };

    const request: ProductBulkRequest = {
      products: [productEditModel],
    };

    return services.productCatalogueService.updateBulkProducts(request).pipe(
      mergeMap((response: ProductBulkResponse) => {
        const updatedProduct = response.products[0];
        // managedListing.loadData();
        return of(updatedProduct);
      })
    );
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});

const productsCellForms = ref<Record<string, FormDefinition>>({});
const statusCellForms = ref<Record<string, FormDefinition>>({});

function makeProductsFormDef(): FormDefinition {
  return {
    form: makeFormModel({
      name: 'productsSelect',
      fieldType: 'form',
      fields: [
        selectField(
          'products',
          '',
          [
            { label: 'Forward', value: Products.FORWARD },
            { label: 'Spot', value: Products.SPOT },
          ],
          {
            placeholder: '',
            multiple: true,
            required: true,
            appendToBody: true,
          }
        ),
      ],
    }),
    validation: null,
  };
}

function makeStatusFormDef(): FormDefinition {
  return {
    form: makeFormModel({
      name: 'statusSelect',
      fieldType: 'form',
      fields: [
        checkboxField(
          'currencyPairEnabled',
          '',
          false,
          {
            required: true,
            checkBoxStyle: 'switch',
            fieldWrapperClass: 'col m-0',
          },
          {}
        ),
      ],
    }),
    validation: null,
  };
}

function getProductsForm(item: Product) {
  let formDef = productsCellForms.value[item.id];

  if (!formDef) {
    productsCellForms.value = {
      ...productsCellForms.value,
      [item.id]: makeProductsFormDef(),
    };
    formDef = productsCellForms.value[item.id];

    const editedItem = managedListing.refs.editedRows.value?.find((i) => i.id === item.id);

    formDef.form.products = editedItem ? editedItem.products : item.products;
  }

  return formDef;
}

function getStatusForm(item: Product) {
  let formDef = statusCellForms.value[item.id];

  if (!formDef) {
    statusCellForms.value = {
      ...statusCellForms.value,
      [item.id]: makeStatusFormDef(),
    };
    formDef = statusCellForms.value[item.id];

    const editedItem = managedListing.refs.editedRows.value?.find((i) => i.id === item.id);

    formDef.form.currencyPairEnabled = editedItem ? editedItem.currencyPairEnabled : item.currencyPairEnabled;
  }
  return formDef;
}

watch(managedListing.refs.editMode, (_currVal: EditTableMode | undefined, prevVal: EditTableMode | undefined) => {
  if (!prevVal || prevVal === EditTableMode.OFF) {
    productsCellForms.value = {};
    statusCellForms.value = {};
  }
});

function iconName(currencyPairEnabled: boolean) {
  return currencyPairEnabled === true ? 'check_circle' : 'motion_photos_off';
}

function iconClass(currencyPairEnabled: boolean) {
  return currencyPairEnabled === true ? 'icon-enabled' : 'icon-disabled';
}

function statusLabel(currencyPairEnabled: boolean) {
  let status = currencyPairEnabled === true ? ProductStatus.ENABLED : ProductStatus.DISABLED;
  return formatStatus(status);
}

function rowChanged(row: Product, event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('row-edited', {
      ...row,
      products: productsCellForms.value[row.id].form.products,
      currencyPairEnabled: statusCellForms.value[row.id].form.currencyPairEnabled,
    } as Product);
  }
}

const productsFormIsInvalid = computed(() => {
  return Object.values(productsCellForms.value).some((form) => !!form.validation?.$invalid);
});

const statusFormIsInvalid = computed(() => {
  return Object.values(statusCellForms.value).some((form) => !!form.validation?.$invalid);
});

const isInvalid = computed(() => productsFormIsInvalid.value || statusFormIsInvalid.value || false);

watch(isInvalid, () => {
  emit('disable-save', isInvalid.value);
});

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    with-simple-scroll
    hover
    items-label="products"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    v-on="{ ...managedListing.listeners, ...$listeners }"
  >
    <template #editor(currencyPairEnabled)="{ item }">
      <VRow class="m-0">
        <ValidatedForm
          :fm="getStatusForm(item).form"
          :validation.sync="getStatusForm(item).validation"
          @form-event="rowChanged(item, $event)"
        />
      </VRow>
    </template>
    <template #cell(currencyPairEnabled)="{ item }">
      <VRow alignV="center">
        <VCol cols="1" align-self="center"
          ><MaterialIcon
            class="status-icon"
            :class="iconClass(item.currencyPairEnabled)"
            :icon="iconName(item.currencyPairEnabled)"
        /></VCol>
        <VCol align-self="center" class="status-banner">{{ statusLabel(item.currencyPairEnabled) }}</VCol>
      </VRow>
    </template>
    <template #cell(notionalAmount)="{ item }">
      <span v-if="item.notionalCurrency">{{
        `${item.notionalCurrency} ${formatCurrencyValue(item.notionalAmount)}`
      }}</span>
      <span v-else>0</span>
    </template>
    <template #cell(products)="{ item }"> {{ showProducts(item.products) }} </template>
    <template #editor(products)="{ item }">
      <VRow>
        <VCol cols="10">
          <ValidatedForm
            :fm="getProductsForm(item).form"
            :validation.sync="getProductsForm(item).validation"
            @form-event="rowChanged(item, $event)"
          />
        </VCol>
      </VRow>
    </template>
    <template #cell(actions)="{ item }">
      <!-- TODO - JG: This is an icon is never shown until API is ready to provide info about products that had been edited  -->
      <MaterialIcon class="go-to-history" @click="$emit('row-details', item)" icon="visibility" v-if="item.updatedBy" />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep {
  .actions-cell {
    text-align: right;
    width: 2em;

    .go-to-history {
      font-size: 2em;
      color: getColor($color-bdPrimary);
      cursor: pointer;
    }
  }

  .status-banner {
    line-height: 0.7em;
  }

  .status-icon {
    font-size: 1.7em;
    font-weight: 500;
    line-height: 0.7em;

    &.icon-enabled {
      color: getColor($color-bdSuccess);
    }
    &.icon-disabled {
      color: getColor($color-bdMediumGrey);
    }
  }
}
</style>
