import { TableFieldDefinition } from 'ah-common-lib/src/models';

const commonFields: TableFieldDefinition[] = [
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    class: 'id-col',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    class: 'product-col',
    sortable: true,
  },
  {
    header: 'Client Name',
    key: 'clientName',
    class: 'client-col',
    sortable: true,
  },
  {
    header: 'Trade Date',
    key: 'createdAt',
    class: 'created-col',
    sortable: true,
  },
  {
    header: 'Booked Rate',
    key: 'fxRateWithCCY',
    sortable: true,
    class: 'booked-col',
    sortKey: 'primaryRate',
  },
  {
    header: 'Notional Values',
    class: 'notional-col',
    key: 'notionalValues',
  },
  {
    header: 'Settlement Date',
    class: 'settlement-col',
    key: 'settlementDate',
    sortable: true,
  },
];

export const clientTradesTableFields: TableFieldDefinition[] = [
  ...commonFields,
  {
    header: 'State',
    key: 'status',
    class: 'state-col',
    sortable: true,
  },
];

export const forwardClientTradesTableFields: TableFieldDefinition[] = [
  ...commonFields,
  {
    header: 'Usage of Contract',
    key: 'usageOfContract',
    class: 'usage-col',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    class: 'state-col',
    sortable: true,
  },
];
