<script lang="ts" setup>
import { ref, computed } from 'vue';
import BDListBlock from 'bd-common/src/components/list/BDListBlock.vue';
import { BhMenuItem } from 'bd-common/src/components/common/menus/BhBaseMenu.vue';
import DropdownMenu, { DropdownMenuItem } from 'bd-common/src/components/common/DropdownMenu.vue';
import { VButton } from 'ah-common-lib/src/common/components';
import ViewTabsMenu from 'bd-common/src/components/common/menus/ViewTabsMenu.vue';
import CardCornerMenu from 'bd-common/src/components/common/menus/CardCornerMenu.vue';
import { useQueryParamRef } from 'ah-common-lib/src/helpers/useQueryParam';
import {
  invitedClientsTableFields,
  onboardedClientsTableFields,
} from '@/app/components/inviteClients/inviteClientsListingTableFields';
import InviteSingleClientModal from '@/app/components/inviteClients/InviteSingleClientModal.vue';
import ClientsListFilter from '@/app/components/inviteClients/ClientsListFilter.vue';
import { ClientOnboardingReport, PaginatedParams } from 'ah-api-gateways';
import InvitedClientsListing from '@/app/components/inviteClients/InvitedClientsListing.vue';
import OnboardedClientsListing from '@/app/components/inviteClients/OnboardedClientsListing.vue';
import { useRouter } from 'vue-router/composables';
import { cloneDeep } from 'lodash';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';

const inviteClientModal = ref<InstanceType<typeof InviteSingleClientModal>>();
const clientsListBlock = ref<InstanceType<typeof BDListBlock>>();
const clientsListing = ref<InstanceType<typeof InvitedClientsListing> | InstanceType<typeof OnboardedClientsListing>>();

const viewMenuKeys = ['clients', 'limits'] as const;

type ViewMenuItem = (typeof viewMenuKeys)[number];

const clientMenuKeys = ['invited', 'onboarded'] as const;

type ClientMenuItem = (typeof clientMenuKeys)[number];

const router = useRouter();

const viewMenuItems: BhMenuItem[] = [
  { label: 'Client List', value: 'clients', icon: 'format_list_bulleted' },
  { label: 'Limits', value: 'limits', icon: 'show_chart' },
];

const clientsMenuItems: BhMenuItem[] = [
  { label: 'Invited', value: 'invited', icon: 'radio_button_partial' },
  { label: 'Onboarded', value: 'onboarded', icon: 'task_alt' },
];

const dropdownMenuItems: DropdownMenuItem[] = [
  { name: 'inviteSingleClient', label: 'Invite Single Client', action: showInviteSingleClientModal },
  { name: 'inviteMultipleClients', label: 'Invite Multiple Clients', action: () => {} },
];

const selectedMenu = useQueryParamRef<ViewMenuItem>('tab', 'clients', {
  deserialize(param) {
    if (!viewMenuKeys.includes(param as ViewMenuItem)) {
      return 'clients';
    }
    return param as ViewMenuItem;
  },
});

const selectedSubMenu = useQueryParamRef<ClientMenuItem>('clients-tab', 'invited', {
  deserialize(param) {
    if (!clientMenuKeys.includes(param as ClientMenuItem)) {
      return 'invited';
    }
    return param as ClientMenuItem;
  },
});

const clientsMenuItemsLabel = computed(() => {
  return clientsMenuItems.find((item) => item.value === selectedSubMenu.value)?.label;
});

const getCustomHeading = (hasCustomFilters: boolean | undefined, filter: any) => {
  if (hasCustomFilters) {
    return filter?.query
      ? `We couldn’t find search results for ${filter.query}`
      : 'We couldn’t find results matching your filter(s)';
  } else {
    return `There are no ${clientsMenuItemsLabel.value} clients to show`;
  }
};

const getWarningSubHeading = (hasCustomFilters: boolean | undefined, filter: any) => {
  if (hasCustomFilters) {
    return filter.query ? 'Please clear or try another search' : 'Please clear or try other filters';
  } else {
    return selectedSubMenu.value === 'invited'
      ? 'Once you invite new clients, they will appear here'
      : 'Once clients complete the full onboarding process, they will appear here';
  }
};

// defaultSortAndPageParams is purposefully not reactive, so as to not trigger any change detection
const defaultSortAndPageParams: Partial<PaginatedParams> = {
  sort: 'createdAt',
  sortDirection: 'DESC',
};

const listConfig = computed(() => {
  return {
    tableFields: selectedSubMenu.value === 'invited' ? invitedClientsTableFields : onboardedClientsTableFields,
  };
});

function showInviteSingleClientModal() {
  inviteClientModal.value?.show();
}

function refreshList() {
  clientsListing.value?.loadData(undefined, true);
}

function onSelectedSubMenuChange(newVal: string) {
  if (clientsListBlock.value) {
    clientsListBlock.value.managedListBlock.refs.sortAndPageParams.value = cloneDeep(defaultSortAndPageParams);
    clientsListBlock.value.managedListBlock.refs.filter.value = undefined;
  }
  selectedSubMenu.value = newVal as ClientMenuItem;
}

function onViewClient(client: ClientOnboardingReport) {
  router.push(`./clients/${client.id}`);
}

const downloadFormatOptions = [
  { value: 'XLSX', text: 'Excel' },
  { value: 'CSV', text: 'CSV' },
];
</script>

<template>
  <div class="view-area" id="clients-view">
    <VButton class="mb-2 btn-ghost client-trade-title"> Client Management </VButton>
    <div class="d-flex justify-content-between mb-4">
      <ViewTabsMenu :value.sync="selectedMenu" :menu-items="viewMenuItems" />
      <DropdownMenu toggle-button-text="Invite Client" :menu-items="dropdownMenuItems" />
    </div>
    <InviteSingleClientModal ref="inviteClientModal" @success="refreshList" />
    <BDListBlock
      pagination-query-param="pagination"
      filter-query-param="filter"
      :listConfig="listConfig"
      :sortAndPageParams="defaultSortAndPageParams"
      showExport
      :download-format-options="downloadFormatOptions"
      ref="clientsListBlock"
      :search-props="{
        queryBy: 'REFERENCE_NUMBER',
        placeholder: 'Search by Name or Company name',
      }"
    >
      <template #card-top>
        <CardCornerMenu
          @update:value="onSelectedSubMenuChange($event)"
          :value="selectedSubMenu"
          :menu-items="clientsMenuItems"
        />
      </template>
      <template #filter="{ props, listeners }">
        <ClientsListFilter v-bind="props" v-on="listeners" :isInvitedFilter="selectedSubMenu === 'invited'" />
      </template>
      <template #list="{ props, listeners, hasCustomFilters, onFilterChange }">
        <component
          :is="selectedSubMenu === 'invited' ? InvitedClientsListing : OnboardedClientsListing"
          :class="selectedSubMenu === 'invited' ? 'invited-clients-listing' : 'onboarded-clients-listing'"
          :noResultsHeading="getCustomHeading(hasCustomFilters.value, props.filter)"
          :noResultsText="getWarningSubHeading(hasCustomFilters.value, props.filter)"
          v-bind="props"
          v-on="{ ...listeners, 'view-client': onViewClient }"
          ref="clientsListing"
        >
          <template #tableNoResultsState>
            <WarningSplash
              :heading="getCustomHeading(hasCustomFilters.value, props.filter)"
              :sub-heading="getWarningSubHeading(hasCustomFilters.value, props.filter)"
              icon="tab_close"
              type="warning"
              class="mt-5 p-5"
            >
              <template #action-area v-if="hasCustomFilters.value">
                <VButton class="btn-primary clear-all-filter-text" @click="onFilterChange({}, true)">
                  {{ props.filter.query ? 'Clear Search' : 'Clear All Filters' }}
                </VButton>
              </template>
            </WarningSplash>
          </template>
        </component>
      </template>
    </BDListBlock>
  </div>
</template>

<style lang="scss" scoped>
.card {
  position: static;
}
</style>
