<script setup lang="ts">
import {
  AmountType,
  FundingType,
  Trade,
  activeMarginTradeStates,
  formatHedgingInstrument,
  fundingTypesToHuman,
  getCcyByAmountType,
  getCurrencyPair,
  getPrimaryCcy,
  tradeStatusBadges,
} from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { SIMPLE_DATE_FORMAT, formatDate } from 'ah-common-lib/src/helpers/time';
import { PropType, computed } from 'vue';
import { useAuthStore } from 'bd-common/src/store/authStore';

const props = defineProps({
  trade: {
    type: Object as PropType<Trade>,
    required: true,
  },
});

const authStore = useAuthStore();

const tradeCurrencyPair = computed(() => getCurrencyPair(props.trade));

const getProductRate = computed(() => getPrimaryCcy(props.trade).clientRate);

const buyCCY = computed(() => getCcyByAmountType(props.trade, AmountType.BUY));

const sellCCY = computed(() => getCcyByAmountType(props.trade, AmountType.SELL));

const tradeStatus = computed(() => tradeStatusBadges[props.trade.status]?.label);

const gbpVariationMargin = computed(() => {
  return props.trade.gbpVmm ?? 0;
});

const tradeMarginIsActive = computed(() => {
  return activeMarginTradeStates.includes(props.trade.state);
});

const gbpInitialMarginValue = computed(() => {
  return props.trade.gbpInitialMarginAmount ?? 0;
});

const gbpInUseMarginValue = computed(() => {
  if (!tradeMarginIsActive.value) {
    return 0;
  }
  return gbpVariationMargin.value + gbpInitialMarginValue.value;
});

const bankName = computed(() => authStore.loggedInIdentity?.partner.name);
</script>

<template>
  <div class="trade-details">
    <h4>Trade ID</h4>
    <p>{{ trade.referenceNumber }}</p>
    <h4>Instrument</h4>
    <p>{{ formatHedgingInstrument(trade.hedgingProduct) }}</p>
    <h4>Trade Date</h4>
    <p>
      {{ formatDate(trade.createdAt, SIMPLE_DATE_FORMAT) }}
    </p>
    <h4>Booked Rate</h4>
    <p>{{ getProductRate.toFixed($ahTradesState.store.useSettingsStore().ratePrecision) }} {{ tradeCurrencyPair }}</p>
    <h4>Sell Amount</h4>
    <p>
      {{ sellCCY.currency }}
      {{ formatCurrencyValue(trade.hasDrawdowns ? sellCCY.remainingClientAmount : sellCCY.clientAmount) }}
    </p>
    <h4>Buy Amount</h4>
    <p>
      {{ buyCCY.currency }}
      {{ formatCurrencyValue(trade.hasDrawdowns ? buyCCY.remainingClientAmount : buyCCY.clientAmount) }}
    </p>
    <h4>Settlement Date</h4>
    <p>
      {{ formatDate(trade.settlementDate, SIMPLE_DATE_FORMAT) }}
    </p>
    <h4>State</h4>
    <p>{{ tradeStatus }}</p>
    <h4>Trade Created By</h4>
    <p>
      <span v-if="trade.oboCreatedBy">{{ bankName }} <i>on behalf of</i></span>
      {{ trade.createdByName }}
    </p>
    <h4>Initial Margin</h4>
    <p v-if="trade.initialMarginFundingType === FundingType.COLLATERAL">
      {{ trade.initialMarginFundingCurrency }}
      {{ formatCurrencyValue(trade.initialMarginCollateralFundingAmount) }}
    </p>
    <p v-else-if="trade.initialMarginFundingType === FundingType.CREDIT">
      {{ trade.initialMarginFundingCurrency }}
      {{ formatCurrencyValue(trade.initialMarginCreditFundingAmount) }}
    </p>
    <p v-else>-</p>
    <h4>Initial Margin Funded with</h4>
    <p>
      {{ fundingTypesToHuman[trade.initialMarginFundingType] }}
    </p>
    <h4>Variation Margin</h4>
    <p>
      GBP
      {{ formatCurrencyValue(trade.gbpVmm) }}
    </p>
    <h4>Total Margin (in use)</h4>
    <p>
      GBP
      {{ formatCurrencyValue(gbpInUseMarginValue) }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.trade-details {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid getColor($color-bdDarkGrey);
}
</style>
