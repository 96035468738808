<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { BModal } from 'bootstrap-vue';
import InviteClientDetailsForm, { InviteClientDetails } from './InviteClientDetailsForm.vue';
import InviteCompanyDetailsForm, { InviteCompanyDetails } from './InviteCompanyDetailsForm.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { resetForm, submitForm } from 'ah-common-lib/src/form/helpers';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { useServices } from 'bd-common/src/services';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { CMSErrorCodes } from 'ah-api-gateways';
import { HttpError, waitForEntityCreation } from 'ah-requests';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

const emit = defineEmits({
  success: () => true,
});

const step = ref<'clientDetails' | 'companyDetails'>('clientDetails');

const clientInviteModal = ref<BModal>();

const clientDetails = ref<InviteClientDetails>();
const companyDetails = ref<InviteCompanyDetails>();
const clientDetailsValidation = ref<FormValidation>();
const companyDetailsValidation = ref<FormValidation>();
const inviteClientDetailsForm = ref<InstanceType<typeof InviteClientDetailsForm>>();
const inviteCompanyDetailsForm = ref<InstanceType<typeof InviteCompanyDetailsForm>>();

const conflictingEmail = ref<string>();
const conflictingRegistrationNumber = ref<string>();

const toast = useToast();
const requestManager = useRequestManager().manager;
const services = useServices();
const authStore = useAuthStore();

const isFormEmailConflicting = computed(
  () => !!(conflictingEmail.value && conflictingEmail.value === clientDetails.value?.applicantEmail)
);

const isFormRegistrationNumberConflicting = computed(
  () =>
    !!(
      conflictingRegistrationNumber.value &&
      conflictingRegistrationNumber.value === companyDetails.value?.registrationNumber
    )
);

const areAllFormsValid = computed(
  () =>
    !!(
      !isFormEmailConflicting.value &&
      !isFormRegistrationNumberConflicting.value &&
      companyDetails.value &&
      companyDetailsValidation.value?.$invalid === false &&
      clientDetails.value &&
      clientDetailsValidation.value?.$invalid === false
    )
);

const showModal = () => {
  step.value = 'clientDetails';
  resetForms();
  clientInviteModal.value?.show();
};

function resetForms() {
  companyDetails.value = {
    companyCountry: '',
    companyName: '',
    entityType: '',
    registrationNumber: '',
  };
  clientDetails.value = {
    applicantEmail: '',
    applicantFirstName: '',
    applicantLastName: '',
    applicantTitle: '',
  };
  clientDetailsValidation.value && resetForm(clientDetailsValidation.value);
  companyDetailsValidation.value && resetForm(companyDetailsValidation.value);
}

function nextStep() {
  clientDetailsValidation.value && submitForm(clientDetailsValidation.value);

  if (clientDetails.value && clientDetailsValidation.value?.$invalid === false && !isFormEmailConflicting.value) {
    step.value = 'companyDetails';
  }
}

function goBack() {
  step.value = 'clientDetails';
}

function submit() {
  clientDetailsValidation.value && submitForm(clientDetailsValidation.value);
  companyDetailsValidation.value && submitForm(companyDetailsValidation.value);

  if (areAllFormsValid.value) {
    conflictingEmail.value = '';
    conflictingRegistrationNumber.value = '';
    requestManager
      .currentOrNew(
        'sendInvite',
        services.clientInvites.createClientInvite(
          {
            partnerId: authStore.userData!.id,
            ...clientDetails.value!,
            ...companyDetails.value!,
          },
          {
            options: {
              errors: { silent: true },
            },
          }
        )
      )
      .pipe(
        mergeMap((idEntity) =>
          waitForEntityCreation(() => services.clientInvites.getClientInvite(idEntity.id)).pipe(
            catchError(() => of(idEntity)),
            map(() => idEntity)
          )
        )
      )
      .subscribe(
        () => {
          toast.success(
            `The client ${clientDetails.value?.applicantFirstName} ${clientDetails.value?.applicantLastName} was successfully invited. They will receive an email invitation shortly.`,
            {
              title: 'Invite sent successfully!',
            }
          );
          clientInviteModal.value?.hide();
          emit('success');
        },
        (e: HttpError) => {
          if (e.response?.data?.code === CMSErrorCodes.CLIENT_INVITE_CONFLICT) {
            if (e.response.data.subErrors?.find((e) => e.field === 'applicantEmail')) {
              conflictingEmail.value = clientDetails.value?.applicantEmail;
              toast.error('If you wish to send them a new reminder, choose the option "Send reminder".', {
                title: 'Email address has already been invited',
              });
            }
            if (e.response.data.subErrors?.find((e) => e.field === 'registrationNumber')) {
              conflictingRegistrationNumber.value = companyDetails.value?.registrationNumber;
              toast.error('Please type a different company number.', {
                title: 'Company number has already been used',
              });
            }
          } else {
            toast.error(
              `Something went wrong when inviting ${clientDetails.value?.applicantFirstName} ${clientDetails.value?.applicantLastName}. Please try again.`,
              {
                title: 'Sorry, the invite was not sent',
              }
            );
          }
        }
      );
  }
}

watch(isFormEmailConflicting, () => {
  inviteClientDetailsForm.value?.setEmailConflictError(isFormEmailConflicting.value);
  if (isFormEmailConflicting.value) {
    step.value = 'clientDetails';
  }
});

watch(isFormRegistrationNumberConflicting, () => {
  inviteCompanyDetailsForm.value?.setCompanyConflictError(isFormRegistrationNumberConflicting.value);
});

defineExpose({
  show: showModal,
});
</script>

<template>
  <BModal centered title="Invite Single Client" ref="clientInviteModal">
    <InviteClientDetailsForm
      v-show="step === 'clientDetails'"
      ref="inviteClientDetailsForm"
      :validation.sync="clientDetailsValidation"
      :model.sync="clientDetails"
    />
    <InviteCompanyDetailsForm
      v-show="step === 'companyDetails'"
      ref="inviteCompanyDetailsForm"
      :validation.sync="companyDetailsValidation"
      :model.sync="companyDetails"
    />
    <template v-slot:modal-footer="{ cancel }">
      <div><span class="required-text">* Mandatory</span></div>
      <div v-if="step === 'clientDetails'">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="btn-primary" @click="nextStep"
          >Next
          <MaterialIcon icon="arrow_forward" />
        </VButton>
      </div>
      <div v-else>
        <VButton class="btn-secondary mr-2" @click="goBack"> <MaterialIcon icon="arrow_back" />Back</VButton>
        <VButton class="btn-primary" @click="submit" :loading="requestManager.anyPending">Send Invite</VButton>
      </div>
    </template>
  </BModal>
</template>

<style lang="scss">
.required-text {
  color: getColor($color-bdDanger);
  font-size: $font-size-sm;
  font-weight: 600;
}

.modal-footer {
  justify-content: space-between;
}
</style>
