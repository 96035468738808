<script setup lang="ts">
import { Trade } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { PropType, ref } from 'vue';
import TradeDetails from './TradeDetails.vue';

const modal = ref<BModal | null>(null);

defineProps({
  trade: {
    type: Object as PropType<Trade>,
    required: false,
  },
});

defineExpose({
  modal,
});
</script>

<template>
  <BModal
    scrollable
    :title="`Trade Details${trade ? ` for ${trade.referenceNumber}` : ''}`"
    modal-class="side-modal"
    ref="modal"
    hide-footer
  >
    <TradeDetails :trade="trade" v-if="trade" />
  </BModal>
</template>
