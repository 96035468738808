import { ClientInviteState } from 'ah-api-gateways';

export const clientInviteStateLabels: Record<ClientInviteState, string> = {
  [ClientInviteState.ACTIVE]: 'Invited',
  [ClientInviteState.ACCEPTED]: 'Accepted Invitation',
  [ClientInviteState.DUE_A_REMINDER]: 'Due a Reminder',
  [ClientInviteState.CANCELLED]: 'Cancelled',
  [ClientInviteState.DECLINED]: 'Declined Invitation',
  [ClientInviteState.EXPIRED]: 'Expired',
};
