import { PaginatedParams } from 'ah-api-gateways';
import { RequestManager } from 'ah-requests';
import { PropType, PropOptions } from 'vue';
import { EditTableMode, TableConfiguration } from '../models';
import { ManagedComposableOptions } from '../helpers/managedComposable';
import { QueryChangeMethod } from '../helpers/useQueryParam';

/**
 * Prop definition object builder for managedListBlock props
 *
 * Use the object builder (or the helper function computeExposedProps) to expose props
 * in any component that uses this composable:
 *
 *   import { defineUseManagedListBlockProps } from 'ah-common-lib/src/listing';
 *   const props = defineProps({
 *     ...defineUseManagedListBlockProps<User>(),
 *   });
 *
 * Returns ComponentObjectPropsOptions
 */
export function defineUseManagedListBlockProps<F extends object = {}>() {
  return {
    listConfig: { type: Object as PropType<TableConfiguration>, required: false },

    /**
     * Possible set common filters of the list
     *
     * These params will remain set, and cannot be overwriteable
     */
    commonFilter: { type: Object as PropType<F>, required: false },
    /**
     * List filter - sync-able via .sync events
     */
    filter: { type: Object as PropType<F>, required: false },
    /**
     * Possible set common sort and page params of the list
     *
     * These params will remain set, and cannot be overwriteable
     */
    commonSortAndPageParams: { type: Object as PropType<Partial<PaginatedParams>>, required: false },
    /**
     * Sort and page params of the list - sync-able via .sync events
     */
    sortAndPageParams: { type: Object as PropType<Partial<PaginatedParams>>, required: false },

    /**
     * List of selected item keys - sync-able via .sync events
     */
    selectedItems: { type: Array as PropType<string[]>, required: false },

    /**
     * Object primary key for purposes of item selection
     */
    itemPrimaryKey: { type: String, default: 'id' },

    /**
     * Query param to sync filters to
     * If set, filters will be synced to the url via a query parameter
     *
     * Please note that this should not be used simultaneously with ManagedListing's filterQueryParam, as their behaviours would conflict
     */
    filterQueryParam: { type: String, required: false },

    /**
     * Query param to sync pagination data to
     * If set, pagination data will be synced to the url via a query parameter
     *
     * Please note that this should not be used simultaneously with ManagedListing's paginationQueryParam, as their behaviours would conflict
     */
    paginationQueryParam: { type: String, required: false },

    /**
     * Change method to use when syncing data to query parameters. Ignored if filterQueryParam/paginationQueryParam are unset
     */
    queryChangeMethod: { type: Object as PropType<QueryChangeMethod>, required: false },
    editMode: { type: String as PropType<EditTableMode>, default: EditTableMode.OFF },
    editedRows: { type: Array as PropType<any[]>, required: false },
    disableSave: { type: Boolean, default: false },
  } satisfies Record<string, PropOptions>;
}

type UseManagedListBlockProps<F extends object = {}> = ReturnType<typeof defineUseManagedListBlockProps<F>>;

export interface UseManagedListBlockOptions<F extends object = {}>
  extends ManagedComposableOptions<UseManagedListBlockProps<F>, UseManagedListBlockEmits<F>> {
  reqManager: RequestManager;
  /**
   * Hook that runs before filters are set. May be a Promise, a falsy value returned will block Filter updates.
   */
  beforeFilterSetHook?: (filter: F) => Promise<F | false> | F | false;
  /**
   * Hook that runs after filters are set.
   */
  afterFilterSetHook?: (filter: F) => void;
}

/**
 * Emits Interface
 *
 * NOTE
 * This interface needs to be extended to allow Type inference (unlike defineProps, which CANNOT use an imported interface, as it needs it to generate props),
 * a limitation relating to https://vuejs.org/guide/typescript/composition-api.html#typing-component-emits
 *
 * Example:
 * // DOES NOT WORK
 * import { UseManagedListBlockEmits } from 'ah-common-lib/src/listing';
 * const emit = defineEmits<UseManagedListBlockEmits>();
 *
 * // WORKS
 * import { UseManagedListBlockEmits } from 'ah-common-lib/src/listing';
 * interface UsersEmit extends UseManagedListBlockEmits {}
 * const emit = defineEmits<UsersEmit>();
 */
export interface UseManagedListBlockEmits<F extends object = {}> {
  (e: 'update:sortAndPageParams', payload: any): void;
  (e: 'update:filter', payload: F): void;
  (e: 'update:selectedItems', payload: string[]): void;
  (e: 'row-clicked', payload: any): void;
  (e: 'update:editedRows', payload: any[]): void;
}
