<script setup lang="ts">
import { ComplianceStatus } from 'ah-api-gateways';
import { complianceStatusLabels } from 'bd-common/src/models/complianceStatus';
import BDStatusPill from 'bd-common/src/components/common/BDStatusPill.vue';

/**
 * Client Status Pill for Bank Direct
 *
 * Currently only supports suspended and approved compliance cases, as those are the only ones shown in the UI
 */

defineProps<{
  status: ComplianceStatus;
}>();

const STATUS_COLORS: Partial<Record<ComplianceStatus, string>> = {
  [ComplianceStatus.SUSPENDED]: 'danger',
  [ComplianceStatus.APPROVED]: 'success',
};
</script>

<template>
  <BDStatusPill class="status-pill" :color="STATUS_COLORS[status] || 'grey'" :label="complianceStatusLabels[status]" />
</template>
