import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const invitedClientsTableFields: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'applicantFullName',
    sortable: true,
  },
  {
    header: 'Company Name',
    key: 'companyName',
    sortable: true,
  },
  {
    header: 'Email',
    key: 'applicantEmail',
    sortable: true,
  },
  {
    header: 'Company Type',
    key: 'entityType',
    sortable: true,
  },
  {
    header: 'Company No',
    key: 'registrationNumber',
    sortable: true,
  },
  {
    header: 'Invited On',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'State',
    key: 'decoratedState',
    sortable: true,
  },
];

export const onboardedClientsTableFields: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'applicant',
    sortable: true,
    sortKey: 'applicantFirstName, applicantLastName',
  },
  {
    header: 'Company Name',
    key: 'name',
    sortable: true,
  },
  {
    header: 'Onboarded On',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    sortable: true,
  },
  {
    header: '',
    tdClass: 'actions-cell',
    key: 'actions',
  },
];
