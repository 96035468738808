import { ComplianceStatus, complianceStatusLabels as baseComplianceStatusLabels } from 'ah-api-gateways';

/**
 * These labels override the labels for compliance states in ah-api-gateways as BankDirect has unique labels for them
 *
 * (Alphabetical order is not currently affected, so no need to edit API responses)
 */
export const bankHedgeComplianceStatusLabels: Record<ComplianceStatus, string> = {
  ...baseComplianceStatusLabels,
  [ComplianceStatus.APPROVED]: 'Active',
};
