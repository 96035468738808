<script setup lang="ts">
import { ClientInviteState } from 'ah-api-gateways';
import { clientInviteStateLabels } from 'bd-common/src/models/clientInviteState';
import BDStatusPill from 'bd-common/src/components/common/BDStatusPill.vue';

/**
 * Client Invite Status Pill for Bank Direct
 */

defineProps<{
  status: ClientInviteState;
}>();

const STATUS_COLORS: Record<ClientInviteState, string> = {
  [ClientInviteState.ACTIVE]: 'primary',
  [ClientInviteState.ACCEPTED]: 'success',
  [ClientInviteState.DUE_A_REMINDER]: 'warning',
  [ClientInviteState.CANCELLED]: 'danger',
  [ClientInviteState.DECLINED]: 'danger',
  [ClientInviteState.EXPIRED]: 'danger',
};
</script>

<template>
  <BDStatusPill class="status-pill" :color="STATUS_COLORS[status]" :label="clientInviteStateLabels[status]">
    <InfoTooltip v-if="status === ClientInviteState.DUE_A_REMINDER" class="reminder-tooltip" />
  </BDStatusPill>
</template>

<style lang="scss" scoped>
.reminder-tooltip {
  color: getColor($color-bdWarning);

  ::v-deep {
    .info-icon {
      margin-bottom: 0;
    }
  }
}
</style>
