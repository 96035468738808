import { PaginatedResponse } from 'ah-api-gateways';
import { ref, watch, onBeforeUnmount, computed, onMounted } from 'vue';
import { DebouncedFunc, debounce, isEqual } from 'lodash';
import {
  UseManagedListBlockEmits,
  UseManagedListBlockOptions,
  defineUseManagedListBlockProps,
} from './useManagedListBlockInterfaces';
import { managedComposableRefs } from '../helpers/managedComposable';
import { RequestState } from 'ah-requests';
import { setupComposableQueryParam } from '../helpers/useQueryParam';

export function useManagedListBlock<
  F extends {
    [key: string]: any;
  } = any
>(options: UseManagedListBlockOptions<F>) {
  options = {
    passiveVModel: true,
    ...options,
  };

  const refs = managedComposableRefs(defineUseManagedListBlockProps<F>(), options);

  const data = ref<PaginatedResponse<any>>();

  const emit: UseManagedListBlockEmits<F> = (e, value?) => options.emit && options.emit(e as any, value);

  const debouncedFilter: DebouncedFunc<(filters: any) => void> = debounce(
    (filter: any) => setFilter(filter, true),
    800
  );

  const debouncedSortAndPageParams: DebouncedFunc<(sortAndPageParams: any) => void> = debounce(
    (sortAndPageParams: any) => setSortAndPageParams(sortAndPageParams),
    800
  );

  const dataDownloadState = ref<RequestState>('idle');

  const isBeforeMounted = ref(true);

  onMounted(() => {
    setTimeout(() => (isBeforeMounted.value = false));
  });

  onBeforeUnmount(() => {
    debouncedFilter.cancel();
    debouncedSortAndPageParams.cancel();
  });

  /**
   * Slot scope for the filters slot. All necessary hooks are exposed, grouped into `listeners` and `props` for a less verbose integration
   *
   * Listeners for filter and sortAndPageParams for listsings are ASYNC by default, so as to delay list updates
   */
  const filterSlotScope = computed(() => ({
    listeners: {
      ['update:filter']: onFilterChange,
      /**
       * DEPRECATED
       *
       * update:filters event is exposed to allow for ListFilter components to use v-bind="props",
       * as they use the prop "filters" rather than "filter"
       */
      ['update:filters']: onFilterChange,
      ['update:sortAndPageParams']: onSortAndPageParamsChange,
    },
    props: {
      filter: refs.filter.value,
      /**
       * DEPRECATED
       *
       * Filters property is exposed to allow for ListFilter components to use v-bind="props",
       * as they use the prop "filters" rather than "filter"
       */
      filters: refs.filter.value,
      sortAndPageParams: refs.sortAndPageParams.value,
    },
    refs,
    onFilterChange: onFilterChange,
    onSortAndPageParamsChange: onSortAndPageParamsChange,
  }));

  /**
   * Slot scope for the list slot. All necessary hooks are exposed, grouped into `listeners` and `props` for a less verbose integration
   *
   * Listeners for filter and sortAndPageParams for listings are SYNC by default (as opposed to the filter listeners, which update on a debounce)
   */
  const listSlotScope = computed(() => ({
    listeners: {
      ['update:filter']: (filter: any) => onFilterChange(filter, true),
      ['update:sortAndPageParams']: (params: any) => onSortAndPageParamsChange(params, true),
      ['update:tableData']: onDataChange,
      ['update:dataDownloadState']: onDownloadStateChange,
      ['disable-save']: onDisableSave,
      ['row-edited']: onRowEdited,
    },
    props: {
      config: refs.listConfig.value?.tableFields ? { tableFields: refs.listConfig.value?.tableFields } : undefined,
      editMode: refs.editMode.value,
      editedRows: refs.editedRows.value,
      filter: refs.filter.value,
      sortAndPageParams: refs.sortAndPageParams.value,
      selectedItems: refs.selectedItems.value,
      primaryKey: refs.itemPrimaryKey.value,
    },
    refs,
    toggleItemSelection: toggleItemSelection,
    onFilterChange: onFilterChange,
    onSortAndPageParamsChange: onSortAndPageParamsChange,
    onDataChange: onDataChange,
    onDownloadStateChange: onDownloadStateChange,
  }));

  function setFilter(filter: any, useFilterSetHook = false) {
    const newFilter = { ...filter, ...refs.commonFilter.value };
    if (refs.filter && !isEqual(newFilter, refs.filter.value)) {
      if (useFilterSetHook && options.beforeFilterSetHook) {
        Promise.resolve(options.beforeFilterSetHook(filter)).then(
          (editedFilter) => {
            if (editedFilter) {
              return setFilter(editedFilter, false);
            }
            refs.filter.value = { ...refs.filter.value };
          },
          () => {
            refs.filter.value = { ...refs.filter.value };
          }
        );
      } else {
        refs.filter.value = newFilter;
        if (options.afterFilterSetHook) {
          options.afterFilterSetHook(filter);
        }
      }
    }
  }

  function setSelectedItems(selectedItems: string[]) {
    if (!isEqual(selectedItems, refs.selectedItems.value)) {
      refs.selectedItems.value = selectedItems;
    }
  }

  function setSortAndPageParams(sortAndPageParams: any) {
    if (!isEqual(sortAndPageParams, refs.sortAndPageParams.value)) {
      refs.sortAndPageParams.value = { ...sortAndPageParams, ...refs.commonSortAndPageParams.value };
    }
  }

  function onFilterChange(filter: any, immediate = isBeforeMounted.value) {
    immediate ? setFilter(filter) : debouncedFilter(filter);
  }

  function onSortAndPageParamsChange(sortAndPageParams: any, immediate = isBeforeMounted.value) {
    immediate ? setSortAndPageParams(sortAndPageParams) : debouncedSortAndPageParams(sortAndPageParams);
  }

  function onDataChange(paginatedResponse: PaginatedResponse<any> | null) {
    data.value = paginatedResponse || undefined;
  }

  function onDownloadStateChange(state?: RequestState) {
    if (state) {
      dataDownloadState.value = state;
    }
  }

  function onDisableSave(disable: boolean) {
    refs.disableSave.value = disable;
  }

  function onRowEdited(row: any) {
    const updatedRows = [...(refs.editedRows.value ?? [])];

    const primaryKey = refs.itemPrimaryKey.value ?? 'id';

    const index = updatedRows.findIndex((editedRow) => editedRow[primaryKey] === row[primaryKey]);

    if (index > -1) {
      updatedRows[index] = row;
    } else {
      updatedRows.push(row);
    }

    refs.editedRows.value = updatedRows;
  }

  function setPage(pageNumber: any, immediate = true) {
    if (pageNumber !== refs.sortAndPageParams.value?.pageNumber) {
      onSortAndPageParamsChange(
        {
          ...refs.sortAndPageParams.value,
          pageNumber,
        },
        immediate
      );
    }
  }

  function isItemSelected(item: any) {
    const itemKey = item[refs.itemPrimaryKey.value || 'id'];
    return refs.selectedItems.value && refs.selectedItems.value.indexOf(itemKey) > -1;
  }

  function toggleItemSelection(item: any, select?: boolean) {
    if (!refs.selectedItems.value) {
      return;
    }
    const itemKey = item[refs.itemPrimaryKey.value || 'id'];
    const updatedSelection = [...refs.selectedItems.value];

    const index = updatedSelection.indexOf(itemKey);
    if (index > -1 && select !== true) {
      updatedSelection.splice(index, 1);
    }
    if (index === -1 && select !== false) {
      updatedSelection.push(itemKey);
    }

    refs.selectedItems.value = updatedSelection;
  }

  function setPageSize(pageSize: any, immediate = true) {
    if (pageSize !== refs.sortAndPageParams.value?.pageSize) {
      onSortAndPageParamsChange(
        {
          ...refs.sortAndPageParams.value,
          pageNumber: 0,
          pageSize,
        },
        immediate
      );
    }
  }

  watch(
    refs.sortAndPageParams,
    () => {
      if (refs.sortAndPageParams.value) {
        setSortAndPageParams(refs.sortAndPageParams.value);
      }
    },
    { immediate: true }
  );

  watch(
    refs.commonFilter,
    () => {
      setFilter({ ...refs.filter.value, ...refs.commonFilter.value });
    },
    { immediate: true }
  );

  /**
   * We setup watchers for query string parameters after all initial data has been set
   */
  setupComposableQueryParam(refs.filterQueryParam, refs.filter);
  setupComposableQueryParam(refs.paginationQueryParam, refs.sortAndPageParams);

  return {
    listSlotScope,
    filterSlotScope,
    refs: {
      ...refs,
      data,
      dataDownloadState,
    },
    toggleItemSelection,
    isItemSelected,
    setPage,
    setFilter,
    setSelectedItems,
    setPageSize,
    setSortAndPageParams,
  };
}
