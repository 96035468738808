<script lang="ts" setup>
import { ref, computed } from 'vue';
import BDListBlock from 'bd-common/src/components/list/BDListBlock.vue';
import ProductsListing from '@/app/components/products/ProductsListing.vue';
import ProductsDetailsModal from '@/app/components/products/ProductsDetailsModal.vue';
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import { BhMenuItem } from 'bd-common/src/components/common/menus/BhBaseMenu.vue';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';
import { VButton } from 'ah-common-lib/src/common/components';
import ViewTabsMenu from 'bd-common/src/components/common/menus/ViewTabsMenu.vue';
import { useQueryParamRef } from 'ah-common-lib/src/helpers/useQueryParam';
import { productsListingTableFields } from '@/app/components/products/productsListingTableFields';
import { PaginatedParams, Product } from 'ah-api-gateways';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { EditTableMode } from 'ah-common-lib/src/models';

const productsListBlock = ref<InstanceType<typeof BDListBlock>>();
const productsDetailsModal = ref<InstanceType<typeof ProductsDetailsModal>>();
const productsListing = ref<InstanceType<typeof ProductsListing>>();

const selectedProduct = ref<Product>();

const viewMenuKeys = ['currencies'] as const;

type ViewMenuItem = (typeof viewMenuKeys)[number];

const editMode = ref<EditTableMode>(EditTableMode.OFF);

const viewMenuItems: BhMenuItem[] = [{ label: 'Currencies', value: 'currencies', icon: 'format_list_bulleted' }];

const selectedMenu = useQueryParamRef<ViewMenuItem>('tab', 'currencies', {
  deserialize(param) {
    if (!viewMenuKeys.includes(param as ViewMenuItem)) {
      return 'currencies';
    }
    return param as ViewMenuItem;
  },
});

function getCustomHeading(hasCustomFilters: boolean | undefined, filter: any) {
  if (hasCustomFilters) {
    return filter.query
      ? `We couldn’t find search results for "${filter.query}"`
      : 'We couldn’t find results matching your filter(s)';
  } else {
    return 'There are no currency pairs to display';
  }
}

function getCustomSubHeading(hasCustomFilters: boolean | undefined, filter: any) {
  if (hasCustomFilters) {
    return filter.query ? 'Please clear or try another search' : 'Please clear or try other filters';
  } else {
    return '';
  }
}

// defaultSortAndPageParams is purposefully not reactive, so as to not trigger any change detection
const defaultSortAndPageParams: Partial<PaginatedParams> = {
  sort: 'sellCurrency',
  sortDirection: 'DESC',
};

const listConfig = computed(() => {
  return {
    tableFields: productsListingTableFields,
  };
});

function onViewHistory(product: any) {
  selectedProduct.value = product;
  productsDetailsModal.value?.modal?.show();
}
</script>

<template>
  <div class="view-area" id="products-view">
    <VButton class="mb-2 btn-ghost product-title"> Product Management </VButton>
    <div class="d-flex justify-content-between mb-4">
      <ViewTabsMenu :value.sync="selectedMenu" :menu-items="viewMenuItems" />
      <VButton class="px-4 py-1" @click="editMode = EditTableMode.ON">
        <MaterialIcon icon="border_color" />Edit</VButton
      >
    </div>
    <BDListBlock
      pagination-query-param="pagination"
      filter-query-param="filter"
      :listConfig="listConfig"
      :sortAndPageParams="defaultSortAndPageParams"
      :showExport="true"
      :editMode.sync="editMode"
      ref="productsListBlock"
      :search-props="{
        queryBy: 'CURRENCY_PAIR',
        placeholder: 'Search by Currency Pair',
      }"
    >
      <template #card-top>
        <div class="card-corner-title d-flex">
          <span class="menu-text">Currencies</span>
        </div>
      </template>
      <!-- TODO The filters will be done in another User Story https://assurehedge.atlassian.net/browse/BH-529 -->
      <template #filter> </template>
      <template #list="{ props, listeners, onFilterChange, hasCustomFilters }">
        <ProductsListing v-bind="props" v-on="{ ...listeners, 'row-details': onViewHistory }" ref="productsListing">
          <template #tableNoResultsState>
            <WarningSplash
              :heading="getCustomHeading(hasCustomFilters.value, props.filter)"
              :subHeading="getCustomSubHeading(hasCustomFilters.value, props.filter)"
              icon="tab_close"
              type="warning"
              class="mt-5 p-5"
            >
              <template #action-area v-if="hasCustomFilters.value">
                <VButton class="btn-primary clear-all-filter-text" @click="onFilterChange({}, true)">
                  {{ props.filter.query ? 'Clear Search' : 'Clear All Filters' }}
                </VButton>
              </template>
            </WarningSplash>
          </template>
        </ProductsListing>
      </template>
    </BDListBlock>
    <ProductsDetailsModal ref="productsDetailsModal" v-if="selectedProduct" :product="selectedProduct" />
  </div>
</template>

<style lang="scss" scoped>
.card {
  position: static;
}

.card-corner-title {
  display: absolute;
  height: 40px;
  top: 0;
  left: 0;

  .menu-text {
    @include themedTextColor($color-bdDarkBlue);
    display: flex;
    align-items: center;
    padding: 0.8rem;
    font-weight: 600;
  }
}
</style>
