<script lang="ts">
export interface DropdownMenuItem {
  name: string;
  label: string;
  action: () => void;
}
</script>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, PropType } from 'vue';
import { createPopper, Instance as PopperInstance, Modifier } from '@popperjs/core';
import { VButton } from 'ah-common-lib/src/common/components';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

defineProps({
  toggleButtonClass: {
    type: String,
    default: 'btn-primary',
  },
  toggleButtonText: {
    type: String,
    default: 'Menu',
  },
  menuItems: {
    type: Array as PropType<DropdownMenuItem[]>,
    required: true,
  },
});

const isMenuShown = ref(false);

const dropdownMenuEl = ref<HTMLElement>();

const dropdownTarget = ref<HTMLSpanElement>();

let popper: PopperInstance | undefined;

function onWindowClick(event: MouseEvent) {
  if (
    isMenuShown.value &&
    dropdownTarget.value &&
    dropdownMenuEl.value &&
    event.target &&
    !isDescendant([dropdownTarget.value, dropdownMenuEl.value], event.target as Element)
  ) {
    toggleShow(false);
  }
}

const minWidth: Modifier<'minWidth', {}> = {
  name: 'minWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    if (state.elements.reference instanceof HTMLElement) {
      state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
    }
  },
};

const topSpaceOffset = {
  name: 'offset',
  options: {
    offset: [0, 3],
  },
};

onMounted(() => {
  if (dropdownMenuEl.value && dropdownTarget.value) {
    popper = createPopper(dropdownTarget.value, dropdownMenuEl.value, {
      placement: 'bottom-end',
      modifiers: [minWidth, topSpaceOffset],
    });
  }
  window.addEventListener('click', onWindowClick);
});

function onItemClick(item: DropdownMenuItem) {
  item.action();
  toggleShow(false);
}

onBeforeUnmount(() => {
  popper?.destroy();
  window.removeEventListener('click', onWindowClick);
});

function toggleShow(show?: boolean) {
  isMenuShown.value = show ?? !isMenuShown.value;

  popper?.update();
}
</script>

<template>
  <span class="dropdown-menu-holder mb-2" :class="{ 'open-menu': isMenuShown }">
    <span class="dropdown-menu-target" ref="dropdownTarget">
      <slot
        name="target"
        v-bind="{
          toggleShow,
        }"
      >
        <VButton @click="toggleShow" :class="['dropdown-menu-btn', toggleButtonClass, { active: isMenuShown }]">
          <slot name="button-content">
            {{ toggleButtonText }}
            <MaterialIcon class="dropdown-menu-expand-icon" icon="expand_more" />
          </slot>
        </VButton>
      </slot>
    </span>

    <div ref="dropdownMenuEl" v-show="isMenuShown" class="dropdown-menu">
      <template v-for="(item, index) in menuItems">
        <slot :name="`item:${item.name}`">
          <div class="dropdown-menu-item clickable" @click="onItemClick(item)" :key="`${index} - ${item.name}`">
            <slot :name="`item:${item.name}:inner`">
              {{ item.label }}
            </slot>
          </div>
        </slot>
      </template>
    </div>
  </span>
</template>

<style lang="scss" scoped>
.dropdown-menu-holder,
.dropdown-menu-target {
  display: inline-block;
}

.dropdown-menu-holder {
  .dropdown-menu-btn {
    font-size: $h4-font-size;
    font-weight: 600;
    transition: transform 0.6s;
  }

  &.open-menu .dropdown-menu-expand-icon ::v-deep > span {
    transform: rotate(180deg);
    vertical-align: middle;
  }

  .dropdown-menu {
    z-index: 1;
    @include themedTextColor($color-bdBlack);
    @include themedBackgroundColor($color-bdWhite);
    font-size: $h6-font-size;

    .dropdown-menu-item {
      padding: 5px 10px;
      border: 1px solid getColor($color-bdMediumGrey);

      &:hover {
        @include themedBackgroundColor($color-bdLightGrey);
      }
    }
  }
}
</style>
