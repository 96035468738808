<script setup lang="ts">
import { PropType, computed, reactive } from 'vue';
import {
  useManagedListFilter,
  defineUseManagedListFilterProps,
  UseManagedListFilterEmits,
  PropGetterSetter,
} from 'ah-common-lib/src/listing';

import { CurrencyExchange, BHTradeStatus, bhTradeStatusBadges } from 'ah-api-gateways';
import BDCurrencyFilterSelect from './BDCurrencyFilterSelect.vue';
import TagMultiSelect from 'ah-common-lib/src/common/components/selects/TagMultiSelect.vue';
import DateSelector from 'bd-common/src/components/form/DateSelector.vue';
import { DateRange } from 'ah-common-lib/src/common/components/DateSelector.vue';
import { startOfDay } from 'date-fns';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FieldOptionObj, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { selectField } from 'ah-common-lib/src/form/models';

interface ClientTradesFilters {
  status?: BHTradeStatus[];
  sellCurrency?: CurrencyExchange[];
  buyCurrency?: CurrencyExchange[];
  dateRange?: DateRange;
}

const typesOfDate: FieldOptionObj[] = [
  { label: 'Started trades within date range', value: 'created' },
  { label: 'Settled trades within date range', value: 'settlementDate' },
];

const props = defineProps({
  ...defineUseManagedListFilterProps<ClientTradesFilters>(),
  allowedStatuses: {
    type: Array as PropType<BHTradeStatus[]>,
    required: false,
  },
});

const dateTypeForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateTypeForm',
    fieldType: 'form',
    fields: [
      selectField('dateType', '', typesOfDate, {
        placeholder: 'Trades within date range',
        required: false,
      }),
    ],
  }),
  validation: null,
});

interface TradesFilterEmit extends UseManagedListFilterEmits<ClientTradesFilters> {}

const emit = defineEmits<TradesFilterEmit>();

const dateFromPropGetterSetters: PropGetterSetter<ClientTradesFilters>[] = typesOfDate.map((o) => ({
  key: `${o.value}From`,
  getter: (filters) => {
    if (dateTypeForm.form.dateType === o.value && filters.value.dateRange) {
      if (!isNaN(filters.value.dateRange.start.valueOf())) {
        return startOfDay(new Date(filters.value.dateRange.start)).toISOString();
      }
    } else {
      return undefined;
    }
  },
  setter: (value: any, filters) => {
    if (value) {
      filters.value.dateRange = { end: new Date(), ...filters.value.dateRange, start: new Date(value) };
      dateTypeForm.form.dateType = o.value;
    }
  },
}));

const dateToPropGetterSetters: PropGetterSetter<ClientTradesFilters>[] = typesOfDate.map((o) => ({
  key: `${o.value}To`,
  getter: (filters) => {
    if (dateTypeForm.form.dateType === o.value && filters.value.dateRange) {
      if (!isNaN(filters.value.dateRange.end.valueOf())) {
        return startOfDay(new Date(filters.value.dateRange.end)).toISOString();
      }
    } else {
      return undefined;
    }
  },
  setter: (value: any, filters) => {
    if (value) {
      filters.value.dateRange = { start: new Date(), ...filters.value.dateRange, end: new Date(value) };
      dateTypeForm.form.dateType = o.value;
    }
  },
}));

const managedListingFilter = useManagedListFilter<ClientTradesFilters>({
  emit,
  props,
  runSettersOnUndefined: true,
  filterKeys: [
    {
      key: 'status',
      getter: (filters) =>
        props.allowedStatuses
          ? filters.value.status?.filter((v: any) => props.allowedStatuses!.includes(v))
          : filters.value.status,
      setter: (value, filters) => {
        if (props.allowedStatuses && value) {
          value = value.filter((v: any) => props.allowedStatuses!.includes(v));
        }
        filters.value.status = value;
      },
    },
    {
      key: 'sellCurrency',
      getter: (filters) => filters.value.sellCurrency || undefined,
      setter: (value, filters) => {
        filters.value.sellCurrency = value;
      },
    },
    {
      key: 'buyCurrency',
      getter: (filters) => filters.value.buyCurrency || undefined,
      setter: (value, filters) => {
        filters.value.buyCurrency = value;
      },
    },
    ...dateFromPropGetterSetters,
    ...dateToPropGetterSetters,
  ],
  filterTransform: {
    setter(dataIn, innerData) {
      if (!typesOfDate.find((i) => dataIn[`${i.value}From`])) {
        if (dateTypeForm.form.dateType) {
          delete innerData.value.dateRange;
        }
        dateTypeForm.form.dateType = null;
      }
    },
  },
  sortAndPageKeys: [],
});

const statusOptions = computed(() =>
  (Object.keys(bhTradeStatusBadges) as BHTradeStatus[])
    .filter((v: any) => !props.allowedStatuses || props.allowedStatuses.includes(v))
    .map((k) => {
      return {
        value: k,
        label: bhTradeStatusBadges[k].label,
      };
    })
);
</script>

<template>
  <div class="row filters">
    <div class="col-2">
      <BDCurrencyFilterSelect
        placeholder="Sell Currency"
        :value.sync="managedListingFilter.filterData.value.sellCurrency"
      />
    </div>
    <div class="col-2">
      <BDCurrencyFilterSelect
        placeholder="Buy Currency"
        :value.sync="managedListingFilter.filterData.value.buyCurrency"
      />
    </div>
    <div class="col-2">
      <TagMultiSelect
        placeholder="State"
        :maxFulltextLabels="1"
        :options="statusOptions"
        :value.sync="managedListingFilter.filterData.value.status"
        itemsCountLabel="states"
      />
    </div>
    <div class="col-2">
      <ValidatedForm :fm="dateTypeForm.form" :validation.sync="dateTypeForm.validation" />
    </div>
    <div class="col-2">
      <DateSelector isRanged :date.sync="managedListingFilter.filterData.value.dateRange" placeholder="Date Range" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters {
  margin-bottom: -1em;
}
</style>
