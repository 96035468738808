<script lang="ts">
import type { PropOptions } from 'vue';
export interface SearchFilters {
  query?: string;
  queryBy?: string[];
}

export const searchFilterProps = {
  ...defineUseManagedListFilterProps<SearchFilters>(),
  queryBy: {
    type: [Array<string>, String],
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
import {
  useManagedListFilter,
  defineUseManagedListFilterProps,
  UseManagedListFilterEmits,
} from 'ah-common-lib/src/listing';
import SearchInput from 'ah-common-lib/src/common/components/SearchInput.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { BFormInput } from 'bootstrap-vue';

const props = defineProps(searchFilterProps);

interface SearchFilterEmit extends UseManagedListFilterEmits<SearchFilters> {}

const emit = defineEmits<SearchFilterEmit>();

const managedListingFilter = useManagedListFilter<SearchFilters>({
  emit,
  props,
  filterKeys: [
    {
      key: 'query',
      getter: (filters) => filters.value.query || undefined,
      setter: (value, filters) => (filters.value.query = value),
    },
    {
      key: 'queryBy',
      getter: (filters) => (filters.value.query ? props.queryBy : undefined),
      setter: () => {
        // queryBy is ignored, as it's provided by props
      },
    },
  ],
  sortAndPageKeys: [],
  runSettersOnUndefined: true,
});
</script>

<template>
  <div class="search-input">
    <SearchInput :search.sync="managedListingFilter.filterData.value.query" :placeholder="placeholder">
      <template #searchIcon>
        <div class="input-group-text">
          <MaterialIcon class="search-icon" icon="search" />
        </div>
      </template>

      <template #clearIcon>
        <div class="input-group-text">
          <MaterialIcon class="close-icon" icon="close" />
        </div>
      </template>
      <template #searchInput>
        <BFormInput
          v-model="managedListingFilter.filterData.value.query"
          :placeholder="placeholder"
          class="search-form-input"
        />
      </template>
    </SearchInput>
  </div>
</template>

<style scoped lang="scss">
.search-input {
  font-size: 1.5em;
  color: getColor($color-bdBlack);

  .search-icon,
  .close-icon {
    position: relative;
    z-index: 10;
    font-size: 1.7em;
    line-height: 0em;
  }

  .close-icon {
    cursor: pointer;
  }

  .search-form-input {
    height: 2.5rem;
  }
}
</style>
