import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const productsListingTableFields: TableFieldDefinition[] = [
  {
    header: 'Sell',
    key: 'sellCurrency',
    sortable: true,
  },
  {
    header: 'Buy',
    key: 'buyCurrency',
    sortable: true,
  },
  {
    header: 'Status',
    key: 'currencyPairEnabled',
    sortable: true,
  },
  {
    header: 'Notional Value',
    key: 'notionalAmount',
    sortable: true,
    info: true,
    labelInfo: 'This represents YTD notional value of all trades',
  },
  {
    header: 'Products',
    key: 'products',
    sortable: true,
  },
  {
    header: '',
    tdClass: 'actions-cell',
    key: 'actions',
  },
];
