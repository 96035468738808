<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { PropType, computed, ref } from 'vue';
import { Product, showProducts, formatStatus } from 'ah-api-gateways';
import { formatDate } from '../../../../../../packages/ah-common-lib/src/helpers/time';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

const modal = ref<BModal | null>(null);

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});

const title = computed(() => `History of pair ${props.product.sellCurrency} ${props.product.buyCurrency}`);

defineExpose({
  modal,
});
</script>

<template>
  <BModal scrollable modal-class="side-modal" ref="modal" hide-footer>
    <template #modal-header>
      <div class="modal-title-wrapper">
        <MaterialIcon class="title-icon" icon="visibility" />
        <h4>{{ title }}</h4>
      </div>
    </template>

    <div class="history-details">
      <h4>Last Edited By</h4>
      <p>{{ product.updatedBy }}</p>
      <h4>Last Edited Date</h4>
      <p>{{ formatDate(product.updatedAt, 'dd-MM-yyyy') }}</p>
      <h4>Previous Values</h4>
      <p v-if="product.previousStatus" class="mb-1">State: {{ formatStatus(product.previousStatus) }}</p>
      <p v-if="product.previousProducts">Products: {{ showProducts(product.previousProducts) }}</p>
    </div>
  </BModal>
</template>

<style lang="scss" scoped>
.history-details {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid getColor($color-bdDarkGrey);
}

.modal-title-wrapper {
  display: flex;
  align-items: center;

  .title-icon {
    margin-right: 0.5em;
    font-size: 1.3em;
    color: getColor($color-bdMediumGrey);
  }
}
</style>
