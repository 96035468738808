import { PaginatedParams } from 'ah-api-gateways';
import { PropType, Ref, PropOptions } from 'vue';
import { ManagedComposableOptions } from '../helpers/managedComposable';

export type PropGetterSetter<F extends object = any> = {
  /**
   * Key for the filter/pagination parameter
   */
  key: string;
  /**
   * Whether the filter is disabled
   *
   * An disabled filter will not run setters or getters
   */
  disabled?: boolean | (() => boolean) | Ref<boolean>;
  /**
   * Getter function for the value of this prop. If `undefined` is returned the prop will not be set
   *
   * Return value will be used in the exposed property
   *
   * The value returned must always be reversible via the setter function, so as to not risk a loop of set/get
   */
  getter: (filter: Ref<F>) => any;
  /**
   * Setter function for the value of this prop. Will not be called if value is `undefined`
   *
   * Method returns no value - state ref should be updated in method
   *
   * The value returned must always be reversible via the getter function, so as to not risk a loop of set/get
   */
  setter: (value: any, filter: Ref<F>) => void;
};

export type Transformer<F extends object = any> = {
  /**
   * Getter function on exposed object - should return an object representing resulting filters to the exported,
   * and receives both the calculated filter and the current innerFilter data
   */
  getter?: (dataOut: any, innerData: Ref<F>) => any;
  /**
   * Setter function on exposed object - has no return value, but can mutate innerData as needed
   */
  setter?: (dataIn: any, innerData: Ref<F>) => void;
};

/**
 * Prop definition object builder for managedListing props
 *
 * Use the object builder (or the helper function computeExposedProps) to expose props
 * in any component that uses this composable:
 *
 *   import { defineUseManagedListFilterProps } from 'ah-common-lib/src/listing';
 *   const props = defineProps({
 *     ...defineUseManagedListFilterProps<User>(),
 *   });
 *
 *
 * Returns ComponentObjectPropsOptions
 */
export function defineUseManagedListFilterProps<F extends object = any>() {
  return {
    /**
     * Filters object. Sync-able via `update:filters` event
     */
    filter: { type: Object as PropType<F>, required: false },
    /**
     * Sorting and pagination parameters object. Sync-able via `update:sortAndPageParams` event
     */
    sortAndPageParams: { type: Object as PropType<Partial<PaginatedParams>>, required: false },
  } satisfies Record<string, PropOptions>;
}

type UseManagedListFilterProps<F extends object = {}> = ReturnType<typeof defineUseManagedListFilterProps<F>>;

export interface UseManagedListFilterOptions<F extends object = {}>
  extends ManagedComposableOptions<UseManagedListFilterProps<F>, UseManagedListFilterEmits<F>> {
  /**
   * Object representing known filters, as a set of getter-setters. Only filters defined in this Array will be used in the filter change events
   */
  filterKeys: PropGetterSetter<F>[];

  /**
   * Transformation functions to serialize/deserialise filter
   *
   * These occur AFTER filterKeys are run
   */
  filterTransform?: Transformer<F>;

  /**
   * Object representing known filters, as a set of getter-setters. Only filters defined in this Array will be used in the filter change events
   */
  sortAndPageKeys: PropGetterSetter<PaginatedParams>[];

  /**
   * Transformation functions to serialize/deserialise sort and page parameters
   *
   * These occur AFTER sortAndPageKeys are run
   */
  sortAndPageTransform?: Transformer<PaginatedParams>;

  /**
   * Whether to run setters when values are undefined. If changes are expected to happen during the filter lifecycle
   * (i.e. changing filter collections)
   * This value should be true
   */
  runSettersOnUndefined?: boolean;
}

/**
 * Emits Interface
 *
 * NOTE
 * This interface needs to be extended to allow Type inference (unlike defineProps, which CANNOT use an imported interface, as it needs it to generate props),
 * a limitation relating to https://vuejs.org/guide/typescript/composition-api.html#typing-component-emits
 *
 * Example:
 * // DOES NOT WORK
 * import { UseManagedListFilterEmits } from 'ah-common-lib/src/listing';
 * const emit = defineEmits<UseManagedListFilterEmits>();
 *
 * // WORKS
 * import { UseManagedListFilterEmits } from 'ah-common-lib/src/listing';
 * interface UsersEmit extends UseManagedListFilterEmits {}
 * const emit = defineEmits<UsersEmit>();
 */
export interface UseManagedListFilterEmits<F extends object = {}> {
  /**
   * Emmited when `filter` changes. Can be used with .sync
   */
  (e: 'update:filter', value: F): void;
  /**
   * Emmited when `sortAndPageParams` changes. Can be used with .sync
   */
  (e: 'update:sortAndPageParams', value: Partial<PaginatedParams>): void;
}
