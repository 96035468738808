<script lang="ts">
export const propsDef = {
  search: {
    type: String,
    required: false,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Search...',
  },
  clearable: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
  label: {
    type: String,
    required: false,
  },
};

export const emitsDef = {
  'update:search': (_value: string) => true,
};
</script>

<script lang="ts" setup>
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormInput } from 'bootstrap-vue';
import { computed, ref, watch } from 'vue';

const props = defineProps(propsDef);
/**
 * {
  (e: 'update:search', value: string): void;
}
 */
const emit = defineEmits(emitsDef);

const searchString = ref<string>('');

const isClearable = computed(() => props.clearable !== false && searchString.value.length);

function resetSearch() {
  searchString.value = '';
}

function onQueryChange() {
  if (props.search !== searchString.value) {
    searchString.value = props.search;
  }
}

watch(() => props.search, onQueryChange, { immediate: true });

function onSearchStringUpdated(value: string) {
  emit('update:search', value);
}

watch(
  () => searchString.value,
  (newVal) => {
    onSearchStringUpdated(newVal);
  }
);
</script>

<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <BInputGroup>
      <BInputGroupPrepend>
        <slot name="searchIcon" />
      </BInputGroupPrepend>
      <slot name="searchInput">
        <BFormInput v-model="searchString" :placeholder="placeholder" />
      </slot>
      <BInputGroupAppend v-if="isClearable" @click="resetSearch">
        <slot name="clearIcon" />
      </BInputGroupAppend>
    </BInputGroup>
  </div>
</template>
