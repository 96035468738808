<script lang="ts" setup>
import {
  HedgingInstruments,
  ListQuery,
  PaginatedParams,
  Trade,
  closedBHTradeStatuses,
  openBHTradeStatuses,
  forwardInstruments,
  getFixedSideCcy,
} from 'ah-api-gateways';
import { VButton } from 'ah-common-lib/src/common/components';
import TradeListing from 'ah-trades/src/components/lists/TradeListing.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import BDListBlock from 'bd-common/src/components/list/BDListBlock.vue';
import { computed, ref, watch } from 'vue';
import { useQueryParamRef } from 'ah-common-lib/src/helpers/useQueryParam';
import TradeDetailsModal from '@/app/components/clientTrades/TradeDetailsModal.vue';
import ClientTradesListFilter from '@/app/components/clientTrades/ClientTradesListFilter.vue';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';
import { DataTransforms } from 'ah-common-lib/src/listing/useManagedListingInterfaces';
import {
  clientTradesTableFields,
  forwardClientTradesTableFields,
} from '@/app/components/clientTrades/clientTradesListingTableFields';
import ChildTradesRowExpansion from '@/app/components/clientTrades/ChildTradesRowExpansion.vue';
import { TableConfiguration } from 'ah-common-lib/src/models';
import ViewTabsMenu from 'bd-common/src/components/common/menus/ViewTabsMenu.vue';
import { BhMenuItem } from 'bd-common/src/components/common/menus/BhBaseMenu.vue';
import CardCornerMenu from 'bd-common/src/components/common/menus/CardCornerMenu.vue';

const viewMenuKeys = ['analytics', 'spots', 'forwards'] as const;

type ViewMenuItem = (typeof viewMenuKeys)[number];

const tradeMenuKeys = ['openTrades', 'closedTrades'] as const;

type TradeMenuItem = (typeof tradeMenuKeys)[number];

const viewMenuItems: BhMenuItem[] = [
  { label: 'Analytics', value: 'analytics', icon: 'leaderboard' },
  { label: 'Spot Positions', value: 'spots', icon: 'format_list_bulleted' },
  { label: 'Forward Positions', value: 'forwards', icon: 'format_list_bulleted' },
];

const tradeMenuItems: BhMenuItem[] = [
  { label: 'Open Trades', value: 'openTrades', icon: 'radio_button_partial' },
  { label: 'Closed Trades', value: 'closedTrades', icon: 'task_alt' },
];

const sortAndPageParams: Partial<PaginatedParams> = {
  sort: 'createdAt',
  sortDirection: 'DESC',
};

const listBlock = ref<InstanceType<typeof BDListBlock> | null>(null);
const listing = ref<InstanceType<typeof TradeListing> | null>(null);

const selectedMenu = useQueryParamRef<ViewMenuItem>('tab', 'spots', {
  deserialize(param) {
    if (!viewMenuKeys.includes(param as any)) {
      return 'spots';
    }
    return param as ViewMenuItem;
  },
});

const selectedTradeMenu = useQueryParamRef<TradeMenuItem>('trade-tab', 'openTrades', {
  deserialize(param) {
    if (!tradeMenuKeys.includes(param as any)) {
      return 'openTrades';
    }
    return param as TradeMenuItem;
  },
});

const listConfig = computed(() => ({
  tableFields: selectedMenu.value === 'forwards' ? forwardClientTradesTableFields : clientTradesTableFields,
}));

const showOpenTrades = computed(() => selectedTradeMenu.value === 'openTrades');

const allowedStatuses = computed(() => (showOpenTrades.value ? openBHTradeStatuses : closedBHTradeStatuses));

const hedgingProduct = computed<HedgingInstruments[]>(() => {
  if (selectedMenu.value === 'spots') return [HedgingInstruments.FX_SPOT];
  if (selectedMenu.value === 'forwards') return forwardInstruments;
  return [];
});

const commonFilter = computed(() => ({
  hedgingProduct: hedgingProduct.value,
  isDrawdown: false,
}));

const menuLabel = computed(() => {
  return viewMenuItems.find((item) => item.value === selectedMenu.value)?.label;
});

const statusLabel = computed(() => {
  return tradeMenuItems.find((item) => item.value === selectedTradeMenu.value)?.label;
});

const tradeDetailsModal = ref<InstanceType<typeof TradeDetailsModal> | null>(null);

const selectedTrade = ref<Trade | null>(null);

const childlistConfig = computed<TableConfiguration>(() => {
  return {
    // We add an extra `.toggle-col` to child columns so columns count matches the parent's
    tableFields: [...listConfig.value.tableFields, { key: 'fakeToggleCol', header: '', class: 'toggle-col' }],
  };
});

function openTradeDetailsModal(item: Trade) {
  selectedTrade.value = item;
  setTimeout(() => {
    tradeDetailsModal.value?.modal?.show();
  });
}

function hasDrawdowns(trade: Trade) {
  return trade.hasDrawdowns;
}

const errorStateHeading = computed(() => `Error loading ${menuLabel.value}`);

const getTotalClientAmount = (trade: Trade) => {
  return getFixedSideCcy(trade).clientAmount;
};

const getUsageOfContractCurrency = (trade: Trade) => {
  return getFixedSideCcy(trade).currency;
};

const getUsageOfContractAmount = (trade: Trade) => {
  const rawValue = getFixedSideCcy(trade).clientAmount - getFixedSideCcy(trade).remainingClientAmount;
  return parseFloat(rawValue.toFixed(2));
};

const calculateUsageOfContract = (trade: Trade) => {
  return (getUsageOfContractAmount(trade) / getTotalClientAmount(trade)) * 100;
};

const formatUsageOfContract = (value: number) => {
  if (value >= 1e12) {
    // 1e12 is scientific notation for the number trillion

    const formattedValue = (value / 1e12).toFixed(1).replace(/\.0$/, '');
    return `${formattedValue}T`;
  } else if (value >= 1e9) {
    // 1e9 is scientific notation for the number billion

    const formattedValue = (value / 1e9).toFixed(1).replace(/\.0$/, '');
    return `${formattedValue}B`;
  } else if (value >= 1e6) {
    // 1e6 is scientific notation for the number million

    const formattedValue = (value / 1e6).toFixed(1).replace(/\.0$/, '');
    return `${formattedValue}M`;
  } else if (value >= 1e3) {
    // 1e3 is scientific notation for the number thousand
    const formattedValue = (value / 1e3).toFixed(1).replace(/\.0$/, '');
    return `${formattedValue}K`;
  } else {
    return `${value}`;
  }
};

watch(commonFilter, () => {
  listBlock.value?.managedListBlock.setFilter({});
});

watch(allowedStatuses, () => {
  listBlock.value?.managedListBlock.setFilter({});
  listing.value?.loadData();
});

function getCustomHeading(hasCustomFilters: boolean | undefined, filter: any) {
  if (hasCustomFilters) {
    return filter.query
      ? `We couldn’t find search results for ${filter.query}`
      : 'We couldn’t find results matching your filter(s)';
  } else {
    return `There are no ${statusLabel.value} for ${menuLabel.value}`;
  }
}

function getCustomSubHeading(hasCustomFilters: boolean | undefined, filter: any) {
  if (hasCustomFilters) {
    return filter.query ? 'Please clear or try another search' : 'Please clear or try other filters';
  } else {
    return 'Once SMEs book new trades, they will appear here';
  }
}

const dataTransforms: DataTransforms<any> = {
  queryTransform: (query) => {
    const out: ListQuery = { ...query };
    out.status = out.status?.length
      ? out.status.filter((i: any) => allowedStatuses.value.includes(i))
      : allowedStatuses.value;
    return out;
  },
};
</script>

<template>
  <div class="view-area" id="client-trades-view">
    <VButton class="mb-2 btn-ghost client-trade-title"> Client Trades </VButton>
    <ViewTabsMenu class="mb-4" :value.sync="selectedMenu" :menu-items="viewMenuItems" />
    <BDListBlock
      :search-props="{
        queryBy: 'REFERENCE_NUMBER',
        placeholder: 'Search by Client or Trade ID',
      }"
      ref="listBlock"
      :listConfig="listConfig"
      :sortAndPageParams="sortAndPageParams"
      :commonFilter="commonFilter"
    >
      <template #card-top>
        <CardCornerMenu :value.sync="selectedTradeMenu" :menu-items="tradeMenuItems" />
      </template>
      <template #list="{ props, listeners, onFilterChange, hasCustomFilters }">
        <TradeListing
          ref="listing"
          class="trade-listing"
          v-bind="props"
          v-on="listeners"
          withSimpleScroll
          pagination-query-param="pagination"
          filter-query-param="filter"
          :dataTransforms="dataTransforms"
          withRowSubView
          @open-row-subview="openTradeDetailsModal"
          :withRowDetails="hasDrawdowns"
          :itemsLabel="menuLabel"
        >
          <template #row-details="slotScope">
            <ChildTradesRowExpansion
              :list-config="childlistConfig"
              v-if="hasDrawdowns(slotScope.item)"
              :trade="slotScope.item"
            >
              <template #cell(usageOfContract)="{ item }">
                <div class="usage-contract-text">
                  <span
                    >{{ getUsageOfContractCurrency(item) }}
                    {{ formatUsageOfContract(getTotalClientAmount(item)) }}</span
                  >
                  out of {{ formatUsageOfContract(getTotalClientAmount(slotScope.item)) }}
                </div>
              </template>
              <template #cell(fakeToggleCol)>
                <span style="opacity: 0; cursor: default" class="nav-icon-holder">
                  <MaterialIcon class="nav-icon" icon="arrow_forward" />
                </span>
              </template>
            </ChildTradesRowExpansion>
          </template>
          <template #tableNoResultsState>
            <WarningSplash
              :heading="getCustomHeading(hasCustomFilters.value, props.filter)"
              :sub-heading="getCustomSubHeading(hasCustomFilters.value, props.filter)"
              icon="tab_close"
              type="warning"
              class="mt-5 p-5"
            >
              <template #action-area v-if="hasCustomFilters.value">
                <VButton class="btn-primary clear-all-filter-text" @click="onFilterChange({}, true)">
                  {{ props.filter.query ? 'Clear Search' : 'Clear All Filters' }}
                </VButton>
              </template>
            </WarningSplash>
          </template>
          <template #tableErrorState>
            <WarningSplash :heading="errorStateHeading" icon="emergency_home" type="danger" class="mt-5 p-5" />
          </template>
          <template #cell(usageOfContract)="{ item }">
            <div>
              <progress :value="calculateUsageOfContract(item)" max="100" class="usage-progress-bar" />
              <p class="usage-contract-text">
                <span
                  >{{ getUsageOfContractCurrency(item) }}
                  {{ formatUsageOfContract(getUsageOfContractAmount(item)) }}</span
                >
                out of {{ formatUsageOfContract(getTotalClientAmount(item)) }}
              </p>
            </div>
          </template>
        </TradeListing>
      </template>

      <template #filter="{ props, listeners }">
        <ClientTradesListFilter v-bind="props" v-on="listeners" :allowedStatuses="allowedStatuses" />
      </template>
    </BDListBlock>
    <TradeDetailsModal v-if="selectedTrade" :trade="selectedTrade" ref="tradeDetailsModal" />
  </div>
</template>

<style lang="scss" scoped>
.clear-all-filter-text {
  margin-top: 2em;
  font-size: $h4-font-size;
  font-weight: 600;
}

.usage-contract-text {
  @include themedTextColor($color-bdDarkBlue);
  font-size: $font-size-sm;
  font-weight: 400;

  span {
    font-weight: 600;
  }
}

progress.usage-progress-bar {
  border: none;
  width: 175px;
  height: 8px;
  opacity: 0.7;

  &::-moz-progress-bar {
    @include themedBackgroundColor($color-bdMediumGrey);
  }

  &::-webkit-progress-value {
    @include themedBackgroundColor($color-bdSuccess);
  }

  &::-webkit-progress-bar {
    @include themedBackgroundColor($color-bdMediumGrey);
  }
}

.trade-listing ::v-deep {
  // Applies to rows inside the row expansion too (i.e. child trades)
  tr:not(.p-datatable-row-expansion) > td {
    width: 10%;

    &.notional-col,
    &.client-col,
    &.instrument-col {
      width: 15%;
    }

    &.usage-col {
      min-width: 215px;
    }

    &.toggle-col {
      width: 1%;
    }
  }
  .p-datatable-row-expansion {
    & > td {
      padding: 0;
    }
  }
}
</style>
