<script lang="ts" setup>
import { PaginatedResponse } from 'ah-api-gateways';
import { PropType, computed } from 'vue';

const props = defineProps({
  data: {
    type: Object as PropType<PaginatedResponse<any>>,
    required: true,
  },
});

const rangeMin = computed(() => Math.max(props.data.pageSize || 10, 1) * Math.max(props.data.pageNumber || 0, 0) + 1);
const rangeMax = computed(() =>
  Math.min(
    Math.max(props.data.pageSize || 10, 1) * (Math.max(props.data.pageNumber || 0, 0) + 1),
    props.data.total || 0
  )
);
</script>

<template>
  <span class="page-result">Showing {{ rangeMin }}-{{ rangeMax }} of {{ data.total }} results</span>
</template>

<style lang="scss" scoped>
.page-result {
  font: $font-size-sm;
}
</style>
