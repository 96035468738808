<script lang="ts" setup>
import { ClientOnboardingReport, ComplianceStatus } from 'ah-api-gateways';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { BModal } from 'bootstrap-vue';
import { PropType, computed, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useServices } from 'bd-common/src/services';
import DropdownMenu, { DropdownMenuItem } from 'bd-common/src/components/common/DropdownMenu.vue';
import { mergeMap } from 'rxjs/operators';
import { waitForCQRSEntityChange } from '../../../../../../packages/ah-requests/helpers/wait';

const props = defineProps({
  onboardedClient: {
    type: Object as PropType<ClientOnboardingReport>,
    required: true,
  },
});

const services = useServices();

const requestManager = useRequestManager().manager;

const iconRef = ref<InstanceType<typeof MaterialIcon>>();

const clientName = computed(
  () => `${props.onboardedClient.applicant?.firstName ?? ''} ${props.onboardedClient.applicant?.lastName ?? ''}`
);

const emit = defineEmits({
  confirm: () => true,
});

const modalShown = ref<boolean>(false);

function suspendClient() {
  return requestManager
    .currentOrNew(
      'updateStatus',
      services.client
        .updateClientOnboardingStatus(props.onboardedClient.id, ComplianceStatus.SUSPENDED)
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => services.client.getClient(props.onboardedClient.id))
          )
        )
    )
    .subscribe(() => {
      emit('confirm');
      hideSuspendModal();
    });
}

function showSuspendModal() {
  modalShown.value = true;
}

function hideSuspendModal() {
  modalShown.value = false;
}

const menuItems: DropdownMenuItem[] = [
  {
    label: 'Suspend Client',
    name: 'suspend-client',
    action: () => showSuspendModal(),
  },
];
</script>

<template>
  <span class="onboarded-client-menu-holder">
    <DropdownMenu :menu-items="menuItems">
      <template #target="{ toggleShow }">
        <MaterialIcon @click="toggleShow" ref="iconRef" class="table-icons menu" icon="more_vert" />
      </template>
    </DropdownMenu>
    <BModal :centered="true" ref="confirmSuspendModal" v-model="modalShown">
      <template #modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <MaterialIcon icon="error" class="mr-2 logout-error-icon" />
          <span class="logout-title">Suspend the client {{ clientName }}?</span>
        </div>
      </template>
      <template #default>
        You are about to suspend {{ clientName }}. This means that they will no longer have access to the platform. Do
        you want to continue?
      </template>
      <template #modal-footer>
        <p class="d-flex justify-content-end w-100">
          <VButton :disable="requestManager.anyPending" @click="hideSuspendModal" class="btn-secondary mr-3">
            Cancel
          </VButton>
          <VButton :loading="requestManager.anyPending" @click="suspendClient" class="btn-danger">
            Yes, Suspend
          </VButton>
        </p>
      </template>
    </BModal>
  </span>
</template>

<style lang="scss" scoped>
.modal-header {
  .modal-title {
    .logout-error-icon {
      font-size: 1.5em;
      color: getColor($color-bdDarkerError);
    }
  }
}
</style>
