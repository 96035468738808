<script setup lang="ts">
import { computed, PropType, useAttrs, useListeners } from 'vue';
import { capitalize, lowerCase } from 'lodash';
import {
  PaginatedQuery,
  ClientInvite,
  ClientInviteListFilters,
  ClientInviteState,
  ListQuery,
  ExportListType,
} from 'ah-api-gateways';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useServices } from 'bd-common/src/services';
import { invitedClientsTableFields } from './inviteClientsListingTableFields';
import ClientInviteStatusPill from './ClientInviteStatusPill.vue';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: invitedClientsTableFields }),
  },
  ...defineUseManagedListingProps<ClientInvite>(),
});

const services = useServices();
const attrs = useAttrs();
const listeners = useListeners();

const reminderTooltipContent = '<h6>Due a Reminder</h6>Over 2 weeks since the <br> invite was sent out';

interface InvitedClientEmits extends UseManagedListingEmits<ClientInvite> {}

const emit = defineEmits<InvitedClientEmits>();

const managedListing = useManagedListing<ClientInvite>({
  loadDataRequest(query: PaginatedQuery<ClientInviteListFilters>) {
    return services.clientInvites.listClientInvites(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
  downloadDataRequest(type, query: ListQuery<ClientInviteListFilters>) {
    return services.clientInvites.downloadClientInvitesReport(query, type as ExportListType);
  },
});

defineExpose({ loadData: managedListing.loadData });

function formatCompanyType(companyType: string): string {
  let formattedCompanyType = lowerCase(companyType).replace(/_/g, ' ');
  formattedCompanyType = formattedCompanyType.replace(/\b\w/g, (char) => capitalize(char));
  return formattedCompanyType;
}
</script>

<template>
  <PaginatedTable
    with-simple-scroll
    class="invited-clients-table"
    hover
    rowClass="invited-client-row"
    items-label="invited clients"
    v-bind="{ ...managedListing.bindings, ...attrs }"
    v-on="{ ...managedListing.listeners, ...listeners }"
  >
    <template #cell(entityType)="{ item }">
      {{ formatCompanyType(item.entityType) }}
    </template>
    <template #cell(decoratedState)="{ item }">
      <ClientInviteStatusPill
        v-if="item.decoratedState !== ClientInviteState.DUE_A_REMINDER"
        :status="item.decoratedState"
      />
      <ClientInviteStatusPill v-else :status="item.decoratedState" v-b-tooltip.html="reminderTooltipContent" />
    </template>
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, 'dd-MM-yyyy') }}
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped></style>
