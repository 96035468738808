<script lang="ts" setup>
import { useSlots, PropType, computed, ref, ExtractPropTypes } from 'vue';
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import SearchFilter, { searchFilterProps } from 'bd-common/src/components/form/SearchFilter.vue';
import { useManagedListBlock } from 'ah-common-lib/src/listing/useManagedListBlock';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import {
  UseManagedListBlockEmits,
  defineUseManagedListBlockProps,
} from 'ah-common-lib/src/listing/useManagedListBlockInterfaces';
import ListPageCounter from './ListPageCounter.vue';
import ListPagination from './ListPagination.vue';
import MaterialIcon from '../common/MaterialIcon.vue';
import { isEmpty, isEqual } from 'lodash';
import { VButton } from 'ah-common-lib/src/common/components';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { EditTableMode } from 'ah-common-lib/src/models';
import { BModal } from 'bootstrap-vue';
import { ExportListType, PaginatedResponse } from 'ah-api-gateways';
import { ListingTriggers } from 'ah-common-lib/src/listing/useManagedListingInterfaces';
import { useToast } from 'ah-common-lib/src/toast';

const slots = useSlots();

/**
 * Base List block component
 *
 * Meant for usage alongside a <Listing> and a <fooFilter> components, controlling filter flow between both
 * All data is synchronized via slot scopes to Listing and Filter components
 *
 * This component does NOT save filter/pagination data. Any query-string-based data saving should happen on the child components
 * (or, as is common, on the Filter components, which MAY ALSO save pagination data)
 */

const props = defineProps({
  ...defineUseManagedListBlockProps(),

  showExport: { type: [Boolean, String], default: false },

  /**
   * Object representing props to set on the Search component
   * Search block will render if this property is set OR if the #search slot is added (in which case this prop is ignored)
   */
  search: {
    type: Object as PropType<Partial<ExtractPropTypes<typeof searchFilterProps>>>,
    required: false,
  },

  showColConfigurator: { type: [Boolean, String], default: false },

  showSizeSelector: { type: [Boolean, String], default: true },

  downloadFormatOptions: {
    type: Array as PropType<{ value: string; text: string }[]>,
    default: () => [
      { value: 'PDF', text: 'PDF' },
      { value: 'XLSX', text: 'Excel' },
      { value: 'CSV', text: 'CSV' },
    ],
  },

  pageOptions: {
    type: Array as PropType<{ label: string; value: number }[]>,
    default: () => [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
});

interface AHPanelListBlockEmits extends UseManagedListBlockEmits {
  (e: 'update:editMode', payload: any): void;
}

const emit = defineEmits<AHPanelListBlockEmits>();

const requestManager = useRequestManager().manager;

const toast = useToast();

const isModalShownWarning = ref<boolean>(false);

const isModalShownSave = ref<boolean>(false);

const filtersShown = ref(false);

const editedRowCountSnapshot = ref<number>(0);

const isSearchBannerShown = computed(() => managedListBlock.refs.filter.value?.hasOwnProperty('query'));

const managedListBlock = useManagedListBlock({
  reqManager: requestManager,
  props,
  emit,
});

const editedRowCount = computed(() => managedListBlock.refs.editedRows.value?.length ?? 0);

const listingTriggers = ref<ListingTriggers<any>>();

const isSavingData = ref<boolean>(false);

const filterSlotScope = computed(() => managedListBlock.filterSlotScope.value);

const isDisableSave = computed(() => managedListBlock.refs.disableSave.value);

/**
 * Slot scope for the list slot. All necessary hooks are exposed, grouped into `listeners` and `props` for a less verbose integration
 *
 * Listeners for filter and sortAndPageParams for listings are SYNC by default (as opposed to the filter listeners, which update on a debounce)
 */
const listSlotScope = computed(() => {
  return {
    ...managedListBlock.listSlotScope.value,
    listeners: {
      ...managedListBlock.listSlotScope.value.listeners,
      ['update:triggers']: onListTriggersUpdate,
    },
    props: managedListBlock.listSlotScope.value.props,
    onListTriggersUpdate: onListTriggersUpdate,
    downloadData: downloadData,
    loadData: loadData,
    hasCustomFilters,
  };
});

function onListTriggersUpdate(triggers: ListingTriggers<any>) {
  listingTriggers.value = triggers;
}

function loadData() {
  listingTriggers.value?.loadData();
}

function downloadData(type: ExportListType) {
  listingTriggers.value?.downloadData(type);
}

function showWarningModal() {
  isModalShownWarning.value = true;
}

function closeWarningModal() {
  isModalShownWarning.value = false;
}

function showSaveModal() {
  editedRowCountSnapshot.value = editedRowCount.value;
  isModalShownSave.value = true;
}

function closeSaveModal() {
  isModalShownSave.value = false;
}

function tryToExitEditMode() {
  if (managedListBlock.refs.editedRows.value && managedListBlock.refs.editedRows.value.length > 0) {
    showWarningModal();
  } else {
    emit('update:editMode', EditTableMode.OFF);
  }
}

function exitEditMode() {
  managedListBlock.refs.editedRows.value = [];
  closeWarningModal();
  emit('update:editMode', EditTableMode.OFF);
}

function seeOnlyEdited() {
  return props.editMode === EditTableMode.ON
    ? emit('update:editMode', EditTableMode.ONLY_EDITED)
    : emit('update:editMode', EditTableMode.ON);
}

function saveChanges() {
  isSavingData.value = true;
  listingTriggers.value?.saveEditedRows().subscribe({
    next() {
      // FIXME Add bulk bar to display errors if the request fail and to show the number of success saved rows
    },
    complete() {
      closeSaveModal();
      isSavingData.value = false;
      emit('update:editMode', EditTableMode.OFF);
      toast.success('The table has been updated sucessfully.', { title: 'Changes saved sucessfully!' });
      loadData();
    },
  });
}

const seeOnlyEditedText = computed(() =>
  props.editMode === EditTableMode.ON ? 'See only edited rows' : 'See all rows'
);

const hasCustomFilters = computed(() => {
  return (
    managedListBlock.refs.filter.value &&
    !isEmpty(managedListBlock.refs.filter.value) &&
    !isEqual(managedListBlock.refs.commonFilter.value, managedListBlock.refs.filter.value)
  );
});

const displayData = computed(() => {
  if (managedListBlock.refs.editedRows.value && managedListBlock.refs.editMode.value === EditTableMode.ONLY_EDITED) {
    return {
      list: managedListBlock.refs.editedRows.value,
      total: managedListBlock.refs.editedRows.value.length,
      pageSize: managedListBlock.refs.editedRows.value.length,
      pageNumber: 0,
    } as PaginatedResponse<any>;
  }
  return managedListBlock.refs.data.value;
});

const footerSlotScope = computed(() => {
  return {
    setPage: managedListBlock.setPage,
    setPageSize: managedListBlock.setPageSize,
    data: displayData,
    dataDownloadState: managedListBlock.refs.dataDownloadState,
    downloadData: downloadData,
    loadData: loadData,
  };
});

const dataDownloadState = computed(() => managedListBlock.refs.dataDownloadState.value);

const isEditing = computed(() => props.editMode && props.editMode !== EditTableMode.OFF);

defineExpose({
  managedListBlock,
});
</script>

<template>
  <div class="card">
    <div class="filter">
      <div class="d-flex">
        <slot name="card-top" v-bind="filterSlotScope" />
        <div class="ml-auto">
          <div class="card-body table-header">
            <div class="header-actions">
              <div class="header-actions-inner">
                <template v-if="slots.filter">
                  <button
                    class="btn btn-ghost clear-filters"
                    :disabled="!hasCustomFilters"
                    @click="managedListBlock.setFilter({})"
                    v-show="filtersShown || hasCustomFilters"
                  >
                    Clear All Filters
                  </button>
                  <button
                    class="btn btn-secondary show-filters"
                    :disabled="isEditing"
                    @click="filtersShown = !filtersShown"
                  >
                    <MaterialIcon :icon="filtersShown ? 'filter_alt_off' : 'filter_alt'" />
                    {{ filtersShown ? 'Hide' : 'Show' }} Filters
                  </button>
                </template>
                <div class="search-bar" v-if="search || slots.search">
                  <slot name="search" v-bind="filterSlotScope">
                    <SearchFilter
                      v-bind="{ queryBy: 'query', ...filterSlotScope.props, ...search }"
                      v-on="filterSlotScope.listeners"
                    />
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body search-box" v-if="isSearchBannerShown && !isEditing">
        <div class="search-results-banner d-flex">
          <div class="search-results">
            <h4 class="mt-1">
              {{ managedListBlock.refs.data.value?.total }} <span class="search-results-text">search result(s)</span>
            </h4>
          </div>
          <div>
            <VButton class="btn-ghost" @click="managedListBlock.setFilter({})">Clear My Search</VButton>
          </div>
        </div>
      </div>
      <div class="filter-box" v-show="filtersShown" v-if="slots.filter">
        <slot name="filter" v-bind="filterSlotScope" />
      </div>
      <div class="card-body edit-container" v-if="isEditing">
        <div class="edit-banner">
          <div class="warning-text-container">
            <MaterialIcon icon="info" class="info-icon" />
            <div>
              <h5 class="warning-title">
                You are in edit mode. Total number of rows edited:
                {{ isModalShownSave ? editedRowCountSnapshot : editedRowCount }}
              </h5>
              <h6>Navigate between pages to edit all your desired currency pairs before clicking 'Save'.</h6>
            </div>
          </div>
          <div class="warning-buttons-container">
            <VButton class="btn-ghost" :disabled="editedRowCount < 1" @click="seeOnlyEdited">{{
              seeOnlyEditedText
            }}</VButton>
            <VButton class="btn-secondary" @click="tryToExitEditMode">Exit Edit Mode</VButton>
            <VButton class="btn-primary" @click="showSaveModal" :disabled="!editedRowCount || isDisableSave">
              <slot name="button-content">
                <MaterialIcon icon="check_circle" />
                Save
              </slot>
            </VButton>
          </div>
        </div>
      </div>
    </div>
    <slot name="list" v-bind="listSlotScope" />
    <div class="card-body table-footer">
      <div class="footer-actions">
        <div class="row-count">
          <slot name="rowCount" v-bind="footerSlotScope">
            <div class="row-count-inner">
              <ListPageCounter v-if="displayData && displayData.total > 0" :data="displayData" />
            </div>
          </slot>
        </div>
        <div class="pagination">
          <slot name="pagination" v-bind="footerSlotScope">
            <ListPagination
              class="pagination-inner"
              v-if="displayData && displayData.total > (displayData.pageSize || 10)"
              :data="displayData"
              @set-page="managedListBlock.setPage"
            />
          </slot>
        </div>
        <div class="export">
          <slot name="export" v-bind="footerSlotScope">
            <div class="export-inner">
              <ListDownloadButton
                v-if="showExport !== false"
                :loading="dataDownloadState === 'pending'"
                class="download-button"
                @download="downloadData"
                :downloadOptions="downloadFormatOptions"
                buttonClass="btn-secondary export-button"
              >
                <template #download-button-content> <MaterialIcon icon="download"></MaterialIcon> Export</template>
              </ListDownloadButton>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <BModal :centered="true" ref="warningModal" modal-class="warning-modal" v-model="isModalShownWarning" hide-footer>
      <template v-slot:modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <MaterialIcon icon="error" class="mr-2 logout-error-icon" />
          <span class="logout-title">Warning! Discard changes?</span>
        </div>
      </template>
      <template v-slot:default>
        <p>
          You are about to exit the edit mode.<strong>
            This action will result in the loss of any unsaved changes.</strong
          >
          Do you want to discard the changes?
        </p>
        <p class="text-right mt-5">
          <VButton class="btn-secondary mr-3" @click="closeWarningModal"> No, Keep Them </VButton>
          <VButton class="btn-danger" @click="exitEditMode"> Yes, Discard </VButton>
        </p>
      </template>
    </BModal>
    <BModal :centered="true" ref="saveModal" modal-class="warning-modal" v-model="isModalShownSave" hide-footer>
      <template v-slot:modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <MaterialIcon icon="error" class="mr-2 logout-error-icon" />
          <span class="logout-title"
            >Save changes to
            {{ `${editedRowCountSnapshot} row${editedRowCountSnapshot.valueOf() > 1 ? 's' : ''}` }}?</span
          >
        </div>
      </template>
      <template v-slot:default>
        <p>
          You made changes to {{ `${editedRowCountSnapshot} row${editedRowCountSnapshot.valueOf() > 1 ? 's' : ''}` }}.
          Do you want to save them?
        </p>
        <p class="text-right mt-5">
          <VButton class="btn-secondary mr-3" @click="closeSaveModal"> Cancel </VButton>
          <VButton class="btn-primary" :loading="isSavingData" @click="saveChanges"> Yes, Save </VButton>
        </p>
      </template>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.card {
  box-shadow: none;

  .filter {
    padding-bottom: 2rem;
  }

  .header-actions-inner {
    display: flex;
    justify-content: flex-end;
  }

  .search-bar {
    width: 300px;
  }

  .filter-box {
    padding: 15px 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px rgba(158, 171, 203, 0.6);
    min-height: 4rem;
  }

  .search-results-banner {
    width: 100%;
    @include themedBackgroundColor($color-bdLightGrey);
    @include themedTextColor($color-bdDarkBlue);
    display: flex;
    justify-content: space-between;
    border: 1px solid getColor($color-bdMediumGrey);
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    padding: 6px 12px;

    .search-results {
      font-size: $h4-font-size;

      .search-results-text {
        font-weight: 400;
      }
    }
  }

  .edit-container {
    padding: 0.8rem;
  }

  .edit-banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid getColor($color-bdWarning);

    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    @include themedBackgroundColor($color-bdLightWarning);
    @include themedTextColor($color-bdDarkBlue);

    .warning-text-container {
      display: flex;

      .info-icon {
        color: getColor($color-bdWarning);
        font-size: 19px;
        margin-right: 0.5rem;
        line-height: 22px;
      }
    }

    .warning-buttons-container {
      display: flex;
      align-items: center;

      .btn {
        margin-left: 1.1rem;
      }
    }

    .warning-title {
      color: getColor($color-bdBlack);
    }

    h6 {
      font-weight: $font-weight-medium;
    }
  }

  .footer-actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .row-count {
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .pagination-inner {
      display: flex;
      justify-content: center;
      height: 30px;
    }

    .export-inner {
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.clear-filters {
  margin-right: 20px;
}

.show-filters {
  margin-right: 23px;
}

.search-box {
  padding-top: 0;
}

::v-deep {
  .warning-modal {
    .modal-body {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .export-button {
    display: flex;
    align-items: center;
    padding-left: 10px !important;
    height: 30px;
  }

  .select-input {
    display: flex;
    margin-right: 10px !important;
    padding: 0 33px 0 12px;
    height: 30px;
  }

  .dropdown-icon {
    margin-right: 10px !important;
    bottom: 0.2em !important;
  }

  .download-button {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .table-footer {
    padding-bottom: 36px;
  }
}
</style>
