<script setup lang="ts">
import { computed, PropType, useAttrs, useListeners } from 'vue';
import {
  PaginatedQuery,
  ClientOnboardingReport,
  ClientOnboardingReportFilters,
  ListQuery,
  ExportListType,
  ComplianceStatus,
} from 'ah-api-gateways';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useServices } from 'bd-common/src/services';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { onboardedClientsTableFields } from './inviteClientsListingTableFields';
import ClientStatusPill from './ClientStatusPill.vue';
import OnboardedClientActionsMenu from './OnboardedClientActionsMenu.vue';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: onboardedClientsTableFields }),
  },
  ...defineUseManagedListingProps<ClientOnboardingReport>(),
});

const services = useServices();
const attrs = useAttrs();
const listeners = useListeners();

interface OnboardedClientsEmit extends UseManagedListingEmits<ClientOnboardingReport> {
  (e: 'view-client', value: ClientOnboardingReport): void;
}

const emit = defineEmits<OnboardedClientsEmit>();

const managedListing = useManagedListing<ClientOnboardingReport>({
  loadDataRequest(query: PaginatedQuery<ClientOnboardingReportFilters>) {
    return services.client.listClientOnboardingReport(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
  downloadDataRequest(type, query: ListQuery<ClientOnboardingReportFilters>) {
    return services.client.downloadClientOnboardingReport(query, type as ExportListType);
  },
});

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    with-simple-scroll
    class="invited-clients-table"
    hover
    rowClass="invited-client-row"
    items-label="invited clients"
    v-bind="{ ...managedListing.bindings, ...attrs }"
    v-on="{ ...managedListing.listeners, ...listeners }"
  >
    <template #cell(applicant)="{ item }">
      {{ `${item.applicant.firstName} ${item.applicant.lastName}` }}
    </template>
    <template #cell(status)="{ item }">
      <ClientStatusPill :status="item.status" />
    </template>
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, 'dd-MM-yyyy') }}
    </template>
    <template #cell(actions)="{ item }">
      <OnboardedClientActionsMenu
        class="client-action"
        v-if="item.status === ComplianceStatus.APPROVED || item.status === ComplianceStatus.REJECTED"
        :onboardedClient="item"
        @confirm="managedListing.loadData()"
      />
      <MaterialIcon class="client-action" @click="$emit('view-client', item)" icon="arrow_forward" />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep {
  .actions-cell {
    text-align: right;

    .client-action {
      font-size: 2em;
      color: getColor($color-bdPrimary);
      cursor: pointer;
      margin-left: 1rem;
    }
  }
}
</style>
