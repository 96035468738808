import { ClientType } from '../client';
import { PaginatedParams } from '../rest';

export interface AMLReportData {
  partnerName: string;
  partnerId: string;
  clientId: string;
  clientName: string;
  clientType: ClientType;
  volumeDifference: number;
  volumeDifferencePercentage: number;
  intendedYearVolume: number;
  actualYearVolume: number;
  currency: string;
  unexpectedCountries: string[];
  unexpectedSellCurrencies: string[];
  unexpectedBuyCurrencies: string[];
  yearStart: string;
  yearEnd: string;
}

export interface AMLReportFilters extends PaginatedParams {
  query: string;
  queryBy: 'clientName' | 'partnerName';
  volumeDifferenceTo: number;
  volumeDifferenceFrom: number;
  volumeDifferencePercentageTo: number;
  volumeDifferencePercentageFrom: number;
  clientType: ClientType;
  yearStartTo: string; // format dd-mm-yyyy
  yearStartFrom: string;
  yearEndTo: string;
  yearEndFrom: string;
}

export const unexpectedVolumeLevels = [
  { max: 10, class: 'status status-green', label: '0-9%' },
  { max: 20, class: 'status status-green', label: '10-19%' },
  { max: 30, class: 'status status-yellow', label: '20-29%' },
  { max: 40, class: 'status status-orange', label: '30-39%' },
  { max: Infinity, class: 'status status-red', label: '40%+' },
];
