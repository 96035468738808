<script lang="ts">
import { ClientInviteCreateRequest } from 'ah-api-gateways';
export type InviteClientDetails = Pick<
  ClientInviteCreateRequest,
  'applicantTitle' | 'applicantFirstName' | 'applicantLastName' | 'applicantEmail'
>;
</script>

<script setup lang="ts">
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { emailField, selectField, textField } from 'ah-common-lib/src/form/models';
import { personTitles } from 'ah-common-lib/src/form/formModels';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import {
  defineManagedModelFormEmits,
  defineManagedModelFormProps,
  useManagedModelForm,
} from 'ah-common-lib/src/form/useManagedModelForm';

const props = defineProps({
  ...defineManagedModelFormProps<InviteClientDetails>(),
});

const emit = defineEmits(defineManagedModelFormEmits<InviteClientDetails>());

const { formDef } = useManagedModelForm({
  props,
  emit,
  formModel: makeFormModel({
    name: 'ClientDetailsForm',
    title: 'Client Details',
    fieldType: 'form',
    state: {
      showRequiredMarkers: true,
    },
    fields: [
      selectField('applicantTitle', 'Title', personTitles, {
        fieldWrapperClass: 'col col-2 pr-0',
        required: true,
        appendToBody: true,
        placeholder: 'Mr.',
      }),
      textField('applicantFirstName', 'First Name', {
        fieldWrapperClass: 'col-5 pr-0',
        required: true,
        placeholder: 'First Name',
      }),
      textField('applicantLastName', 'Last Name', {
        fieldWrapperClass: 'col-5',
        required: true,
        placeholder: 'Last Name',
      }),
      emailField('applicantEmail', 'Email Address', { required: true, placeholder: 'Email Address' }),
    ],
  }),
});

defineExpose({
  setEmailConflictError(error: boolean) {
    const emailField = getChildModel(formDef.form, 'applicantEmail')!;
    setState(
      emailField,
      'errors',
      error
        ? [
            {
              name: 'emailConflict',
              error: 'Duplicated Email Address',
            },
          ]
        : []
    );
  },
});
</script>

<template>
  <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" v-on="$listeners" />
</template>

<style lang="scss" scoped>
::v-deep {
  .v-select .vs__search {
    padding-right: 0;
  }
}
</style>
