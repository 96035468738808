<script lang="ts">
export interface BhMenuItem {
  value: string;
  label: string;
  icon?: string;
}

export const propsOptions = {
  value: {
    type: String,
    default: '',
  },
  menuItems: {
    type: Array as PropType<BhMenuItem[]>,
    required: true,
  },
};

export const emitsOptions = {
  'update:value': (_value: string) => true,
};
</script>

<script lang="ts" setup>
import { PropType } from 'vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

defineProps(propsOptions);

defineEmits(emitsOptions);
</script>

<template>
  <div class="simple-menu">
    <div
      v-for="(menuItem, index) in menuItems"
      :key="`${index} - ${menuItem.value}`"
      class="menu-item"
      :class="{ active: value === menuItem.value }"
      @click="$emit('update:value', menuItem.value)"
    >
      <div class="menu-item-inner">
        <MaterialIcon class="menu-icons mr-2" v-if="menuItem.icon" :icon="menuItem.icon" />
        <span class="menu-text">{{ menuItem.label }}</span>
      </div>
    </div>
  </div>
</template>
