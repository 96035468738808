<script lang="ts" setup>
import { PropType, computed, reactive, ref } from 'vue';
import TradeListing from 'ah-trades/src/components/lists/TradeListing.vue';
import { Trade, PaginatedResponse } from 'ah-api-gateways';
import { VButton } from 'ah-common-lib/src/common/components';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import TradeDetailsModal from '@/app/components/clientTrades/TradeDetailsModal.vue';
import { TableConfiguration } from 'ah-common-lib/src/models';

defineProps({
  trade: {
    type: Object as PropType<Trade>,
    required: true,
  },
  listConfig: { type: Object as PropType<TableConfiguration>, required: true },
});

const tradeDetailsModal = ref<InstanceType<typeof TradeDetailsModal> | null>(null);
const listing = ref<InstanceType<typeof TradeListing> | null>(null);

const state = reactive<{
  totalCount: number;
  selectedTrade: Trade | null;
}>({
  totalCount: 0,
  selectedTrade: null,
});

const showingCount = computed(() => {
  return Math.min(state.totalCount, 5);
});

const isChildTradesMoreThanFive = computed(() => {
  return state.totalCount > 5;
});

function onCountsChange(tableData: Partial<PaginatedResponse<Trade>> | null) {
  state.totalCount = tableData?.total ?? 0;
}

function openTradeDetailsModal(item: Trade) {
  state.selectedTrade = item;
  setTimeout(() => {
    tradeDetailsModal.value?.modal?.show();
  });
}
</script>

<template>
  <div class="child-trades-block">
    <div class="child-trades-header">
      Child Trades <span>(showing {{ showingCount }} of {{ state.totalCount }})</span>
    </div>
    <TradeListing
      class="child-trades"
      ref="listing"
      :config="listConfig"
      :filter="{ parentTradeId: trade?.id }"
      @update:tableData="onCountsChange"
      :sort-and-page-params="{ pageSize: 5 }"
      withRowSubView
      @open-row-subview="openTradeDetailsModal"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
        <slot :name="name" v-bind="scope" />
      </template>
    </TradeListing>
    <div class="child-trades-footer">
      <VButton class="btn-primary" v-if="isChildTradesMoreThanFive">
        See All Child Trades <MaterialIcon class="arrow-icon ml-2" icon="arrow_forward"
      /></VButton>
    </div>
    <TradeDetailsModal v-if="state.selectedTrade" :trade="state.selectedTrade" ref="tradeDetailsModal" />
  </div>
</template>

<style lang="scss" scoped>
.child-trades-block {
  .child-trades ::v-deep {
    .p-datatable {
      margin: 0;

      .p-datatable-wrapper {
        overflow-y: hidden;
      }
      .p-datatable-thead {
        display: none;
      }
      .p-datatable-tbody > tr > td {
        .status-pill {
          font-size: 0.6rem;
          padding: 0.15rem 0.5rem;
        }
        .drawdown-text {
          top: 1.2rem;
        }
      }
    }
    .usage-contract-text {
      @include themedTextColor($color-bdDarkBlue);
      font-size: $font-size-sm;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
  }

  .child-trades-header {
    color: getColor($color-bdDarkBlue);
    background: getColorWithOffsetLightness($color-bdPrimary, 30%);
    border-bottom: 1px solid getColor($color-bdMediumGrey);
    padding: 1em;
    font-size: 14px;
    font-weight: 600;
    span {
      font-weight: 400;
    }
  }

  .child-trades-footer {
    padding: 0.5em 1.5em;
    background: getColorWithOffsetLightness($color-bdPrimary, 30%);
    text-align: right;
    font-size: $font-size-base;
    min-height: 3.5em;

    .arrow-icon {
      font-size: 1.7em;
      line-height: 0.5em;
      vertical-align: middle;
    }
  }
}
</style>
