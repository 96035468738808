<script setup lang="ts">
import { computed } from 'vue';
import { startOfDay, endOfDay } from 'date-fns';
import { ClientInviteState, ComplianceStatus } from 'ah-api-gateways';
import DateSelector from 'bd-common/src/components/form/DateSelector.vue';
import { bankHedgeComplianceStatusLabels } from 'bd-common/src/models/clientOnboardedState';
import { useSettingsStore } from 'bd-common/src/store/settingsStore';
import { clientInviteStateLabels } from 'bd-common/src/models/clientInviteState';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { VRow, VCol } from 'ah-common-lib/src/common/components';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListingEmits,
} from 'ah-common-lib/src/listing';
import { DateRange } from 'ah-common-lib/src/common/components/DateSelector.vue';
import TagMultiSelect from 'bd-common/src/components/form/TagMultiSelect.vue';

interface ClientFilters {
  entityType?: Array<string>;
  createdAt?: DateRange;
  onboardedOn?: DateRange;
  decoratedState?: Array<ClientInviteState>;
  status?: Array<ComplianceStatus>;
}

const settingsStore = useSettingsStore();
settingsStore.loadEntityTypes();

const props = defineProps({
  ...defineUseManagedListFilterProps<ClientFilters>(),
  isInvitedFilter: {
    type: Boolean,
  },
});

const emit = defineEmits<InvitedClientEmits>();

interface InvitedClientEmits extends UseManagedListingEmits<ClientFilters> {}

const entityTypeOptions = computed<FieldOptionObj[]>(() =>
  settingsStore.entityTypesList.map((entityType) => ({ label: entityType.legalForm, value: entityType.id }))
);

const stateOptions: FieldOptionObj[] = Object.entries(clientInviteStateLabels).map(([key, value]) => {
  return { label: value, value: key };
});

const complianceStatusOptions: FieldOptionObj[] = [ComplianceStatus.APPROVED, ComplianceStatus.SUSPENDED].map(
  (value) => ({ value, label: bankHedgeComplianceStatusLabels[value] as string })
);

const { filterData } = useManagedListFilter<ClientFilters>({
  emit,
  props,
  sortAndPageKeys: [],
  runSettersOnUndefined: true,
  filterKeys: [
    {
      key: 'entityType',
      getter: (filters) => filters.value.entityType || undefined,
      setter: (value, filters) => {
        filters.value.entityType = value;
      },
    },

    {
      key: 'createdAtFrom',
      getter: (filters) => {
        if (filters.value.createdAt?.start && !isNaN(new Date(filters.value.createdAt.start).valueOf())) {
          return startOfDay(new Date(filters.value.createdAt.start)).toISOString();
        }
      },
      setter: (value, filters) => {
        if (filters.value.createdAt) {
          filters.value.createdAt.start = new Date(value);
        } else {
          filters.value.createdAt = {
            start: startOfDay(new Date(value)),
            end: endOfDay(new Date(value)),
          };
        }
      },
    },
    {
      key: 'createdAtTo',
      getter: (filters) => {
        if (filters.value.createdAt?.end && !isNaN(new Date(filters.value.createdAt.end).valueOf())) {
          return endOfDay(new Date(filters.value.createdAt.end)).toISOString();
        }
      },

      setter: (value, filters) => {
        if (filters.value.createdAt) {
          filters.value.createdAt.end = new Date(value);
        } else {
          filters.value.createdAt = {
            start: startOfDay(new Date(value)),
            end: endOfDay(new Date(value)),
          };
        }
      },
    },
    {
      key: 'decoratedState',
      disabled: () => !props.isInvitedFilter,
      getter: (filters) => filters.value.decoratedState,
      setter: (value, filters) => {
        filters.value.decoratedState = value;
      },
    },
    {
      key: 'status',
      disabled: () => props.isInvitedFilter,
      getter: (filters) => filters.value.status,
      setter: (value, filters) => {
        if (Array.isArray(value)) {
          value = [...value].filter((item: any) => complianceStatusOptions.find((option) => option.value === item));
          if (!value.length) {
            value = undefined;
          }
        }
        filters.value.status = value;
      },
    },
  ],
});
</script>

<template>
  <VRow>
    <VCol cols="4" lg="2">
      <TagMultiSelect
        placeholder="Company type"
        :options="entityTypeOptions"
        :maxFulltextLabels="1"
        :value.sync="filterData.entityType"
        itemsCountLabel="company types"
      />
    </VCol>
    <VCol v-if="!isInvitedFilter" cols="4" lg="2">
      <DateSelector isRanged placeholder="Onboarded Date" :date.sync="filterData.createdAt" />
    </VCol>
    <VCol v-if="isInvitedFilter" cols="4" lg="2">
      <DateSelector isRanged placeholder="Invited date" :date.sync="filterData.createdAt" />
    </VCol>
    <VCol v-if="isInvitedFilter" cols="4" lg="2">
      <TagMultiSelect
        placeholder="State"
        :options="stateOptions"
        :maxFulltextLabels="1"
        :value.sync="filterData.decoratedState"
        itemsCountLabel="states"
      />
    </VCol>
    <VCol v-if="!isInvitedFilter" cols="4" lg="2">
      <TagMultiSelect
        placeholder="State"
        :options="complianceStatusOptions"
        :maxFulltextLabels="1"
        :value.sync="filterData.status"
        itemsCountLabel="states"
      />
    </VCol>
  </VRow>
</template>
