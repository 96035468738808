<script lang="ts">
import { ClientInviteCreateRequest } from 'ah-api-gateways';
export type InviteCompanyDetails = Pick<
  ClientInviteCreateRequest,
  'companyName' | 'companyCountry' | 'entityType' | 'registrationNumber'
>;
</script>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { textField, selectField } from 'ah-common-lib/src/form/models';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import {
  defineManagedModelFormEmits,
  defineManagedModelFormProps,
  useManagedModelForm,
} from 'ah-common-lib/src/form/useManagedModelForm';
import { useSettingsStore } from 'bd-common/src/store/settingsStore';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';

const settingsStore = useSettingsStore();

const props = defineProps({
  ...defineManagedModelFormProps<InviteCompanyDetails>(),
});

const emit = defineEmits(defineManagedModelFormEmits<InviteCompanyDetails>());

const countryOptions = computed<FieldOptionObj[]>(() =>
  settingsStore.allCountries.map((country) => ({
    value: country.cc,
    label: country.name,
  }))
);

const entityTypeOptions = computed<FieldOptionObj[]>(() =>
  settingsStore.entityTypesList.map((t) => ({ label: t.legalForm, value: t.id }))
);

const { formDef } = useManagedModelForm({
  props,
  emit,
  formModel: makeFormModel({
    name: 'CompanyDetailsForm',
    title: 'Company Details',
    fieldType: 'form',
    state: {
      showRequiredMarkers: true,
    },
    fields: [
      textField('companyName', 'Company Name', { required: true, placeholder: 'Company Name' }),
      selectField('companyCountry', 'Country of Incorporation', countryOptions.value, {
        required: true,
        placeholder: 'Country of Incorporation',
      }),
      selectField('entityType', 'Company Type', entityTypeOptions.value, {
        required: true,
        placeholder: 'Select Company Type',
      }),
      textField('registrationNumber', 'Company Number', { required: true, placeholder: 'Company Number' }),
    ],
  }),
});

watch(countryOptions, () => {
  const countryCodeField = getChildModel(formDef.form, 'companyCountry')!;
  setState(countryCodeField, 'options', countryOptions.value);
});

watch(entityTypeOptions, () => {
  const entityTypeField = getChildModel(formDef.form, 'entityType')!;
  setState(entityTypeField, 'options', entityTypeOptions.value);
});

settingsStore.loadCountries();
settingsStore.loadEntityTypes();

defineExpose({
  setCompanyConflictError(error: boolean) {
    const registrationNumberField = getChildModel(formDef.form, 'registrationNumber')!;
    setState(
      registrationNumberField,
      'errors',
      error
        ? [
            {
              name: 'registrationNumberConflict',
              error: 'Duplicated Company No.',
            },
          ]
        : []
    );
  },
});
</script>

<template>
  <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" v-on="$listeners" />
</template>
