import { PaginatedParams } from 'ah-api-gateways';
import { ref, watch, onBeforeMount, computed, Ref } from 'vue';
import { cloneDeep, isEqual, keysIn } from 'lodash';
import {
  PropGetterSetter,
  UseManagedListFilterEmits,
  UseManagedListFilterOptions,
  defineUseManagedListFilterProps,
} from './useManagedListFilterInterfaces';
import { managedComposableRefs } from '../helpers/managedComposable';

function isFilterDisabled(filter: PropGetterSetter) {
  return (
    filter.disabled === true ||
    (filter.disabled as Ref)?.value === true ||
    (typeof filter.disabled === 'function' && filter.disabled())
  );
}

export function useManagedListFilter<
  F extends {
    [key: string]: any;
  } = any
>(options: UseManagedListFilterOptions<F>) {
  const sortAndPageData = ref<PaginatedParams>({});

  const filterData = ref({} as F) as Ref<F>;

  /**
   * Copy of last emmited value to determine whether data was changed relative to last event
   * Checking agaisnt last emmited value prevents issues with debouncing
   * Resets when filters prop is updated
   */
  const emmitedFilter = ref({} as F) as Ref<F>;

  const refs = managedComposableRefs(defineUseManagedListFilterProps<F>(), options);

  const emit: UseManagedListFilterEmits<F> = (e, value) => options.emit && options.emit(e as any, value);

  onBeforeMount(() => {
    setTimeout(() => {
      // We check for filter changes on setTimeout, as to ensure any sync has already happened (i.e. from query string parameters)
      onfilterDataChange();
      onSortAndPageDataChange();
    });
  });

  const computedFilter = computed(() => {
    const out: any = {};
    keysIn(refs.filter.value).forEach((k) => {
      if (options.filterKeys.findIndex((i) => i.key === k) === -1) {
        out[k] = refs.filter.value[k];
      }
    });

    options.filterKeys.forEach((filter) => {
      if (isFilterDisabled(filter)) {
        return;
      }
      const value = filter.getter(filterData);
      if (value !== undefined) {
        out[filter.key] = value;
      }
    });

    return options.filterTransform?.getter ? options.filterTransform.getter(out, refs.filter as any) : out;

    return out;
  });

  const computedSortAndPageParams = computed(() => {
    const out: any = {};
    keysIn(refs.sortAndPageParams.value).forEach((k) => {
      if (options.sortAndPageKeys.findIndex((i) => i.key === k) === -1) {
        out[k] = (refs.sortAndPageParams.value as any)[k];
      }
    });

    options.sortAndPageKeys.forEach((f) => {
      const value = f.getter(sortAndPageData);
      if (value !== undefined) {
        out[f.key] = value;
      }
    });

    return options.sortAndPageTransform?.getter
      ? options.sortAndPageTransform.getter(out, refs.sortAndPageParams as any)
      : out;
  });

  function onFilterPropChange() {
    options.filterKeys.forEach((filter) => {
      if (isFilterDisabled(filter)) {
        return;
      }
      if (options.runSettersOnUndefined || (refs.filter.value && refs.filter.value[filter.key] !== undefined)) {
        filter.setter((refs.filter.value || {})[filter.key], filterData);
      }
    });

    if (options.filterTransform?.setter) {
      options.filterTransform?.setter(refs.filter.value || {}, filterData);
    }

    // We re-set ref value to itself to ensure any new props are reactive (this is a Vue2 limitation)
    filterData.value = cloneDeep(filterData.value);
  }

  function onSortingPropChange() {
    options.sortAndPageKeys.forEach((f) => {
      if (
        refs.sortAndPageParams.value &&
        (options.runSettersOnUndefined || (refs.sortAndPageParams.value as any)[f.key] !== undefined)
      ) {
        f.setter(((refs.sortAndPageParams.value as any) || {})[f.key], sortAndPageData);
      }
    });

    if (options.sortAndPageTransform?.setter) {
      options.sortAndPageTransform?.setter(refs.sortAndPageParams.value || {}, sortAndPageData);
    }

    // We re-set ref value to itself to ensure any new props are reactive (this is a Vue2 limitation)
    sortAndPageData.value = cloneDeep(sortAndPageData.value);
  }

  function onfilterDataChange() {
    if (!isEqual(computedFilter.value, refs.filter.value) || !isEqual(emmitedFilter.value, computedFilter.value)) {
      emmitedFilter.value = cloneDeep(computedFilter.value);
      emit('update:filter', emmitedFilter.value);
    }
  }

  function onSortAndPageDataChange() {
    if (!isEqual(computedSortAndPageParams.value, refs.sortAndPageParams.value)) {
      emit('update:sortAndPageParams', computedSortAndPageParams.value);
    }
  }

  watch(refs.filter, onFilterPropChange, { deep: true, immediate: true });
  refs.sortAndPageParams && watch(refs.sortAndPageParams, onSortingPropChange, { deep: true, immediate: true });
  watch(computedFilter, onfilterDataChange);
  watch(computedSortAndPageParams, onSortAndPageDataChange);

  return {
    filterData,
    sortAndPageData,
  };
}
